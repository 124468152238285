import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Component from "components/component";
import mapDispatchToProps from "../../lib/map-dispatch-to-props";
import * as modalActions from "../../actions/modals";
import LoginModal from "./login-modal";
import ChooseProjectsModal from "./choose-projects";
import ProjectItemUpdateSuccess from "./project-item-update-success";
import ProductPricingDetailsModal from "./product-pricing-details";
import WhatsThisModal from "./whats-this";
import ProductInquiryModal from "./product-inquiry";
import ProductZoom from "./product-zoom";
import SharePDPModal from "./share-pdp";
import ShareProjectAndOrderModal from "./share-project-and-order";
import ViewGallery from "./view-gallery";
import ProjectUpdateItemError from "./project-update-item-error";
import SleepingInfo from "./sleeping-info";
import SectionalDrawings from "./sectional-drawings";
import SendPaymentForm from "./send-payment-form";
import DeleteProject from "./delete-project";
import ResaleFormInstructions from "./resale-form-instructions";
import StatusDetails from "./status-details";
import SalesTaxInstructions from "./sales-tax-form-instructions";
import TradeAccessPending from "./trade-access-pending";

export class Modal extends Component {
  static propTypes = {
    actions: PropTypes.object,
    metaData: PropTypes.object,
    open: PropTypes.bool.isRequired,
    type: PropTypes.string
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.open && !this.props.open) {
      document.body.classList.add("no-scroll");
    } else if (this.props.open && !newProps.open) {
      document.body.classList.remove("no-scroll");
    }
  }

  closeModal = e => {
    e.preventDefault();
    this.props.actions.modalActions.closeModal();
  };

  closeModalIfOverlay = e => {
    if (!(e.target.classList.contains("inner") || $(e.target).parents(".inner").length)) {
      // If user clicks the modal, but NOT within the .inner element.
      this.props.actions.modalActions.closeModal();
    }
  }

  UNSAFE_componentDidUpdate(oldProps) {
    if (!oldProps.open && this.props.open) {
      this.refs.modalContainer.addEventListener("click", e => {
        const modal = this.refs.modalInner;

        if (modal !== e.target && !modal.contains(e.target)) {
          this.props.actions.modalActions.closeModal();
        }
      });
    }
  }

  render() {
    const { metaData, type, open } = this.props;

    if (!open) return null;

    return (
      <div
        className={`modal-overlay react-modal active ${type}`}
        ref="modalContainer"
        onClick={this.closeModalIfOverlay}
      >
        <div className="inner" ref="modalInner">
          <button className="close-modal" onClick={this.closeModal} />

          {type === "login" && <LoginModal metaData={metaData} />}

          {type === "project-update-item-success" && (
            <ProjectItemUpdateSuccess metaData={metaData} />
          )}

          {type === "choose-projects" && (
            <ChooseProjectsModal metaData={metaData} />
          )}

          {type === "product-pricing-details" && (
            <ProductPricingDetailsModal metaData={metaData} />
          )}

          {type === "whats-this" && <WhatsThisModal metaData={metaData} />}

          {type === "product-inquiry" && (
            <ProductInquiryModal metaData={metaData} />
          )}

          {type === "product-zoom" && <ProductZoom metaData={metaData} />}

          {type === "share-product" && <SharePDPModal metaData={metaData} />}

          {(type === "share-project" || type === "share-order") && (
            <ShareProjectAndOrderModal metaData={metaData} />
          )}

          {(type === "view-gallery") && (
            <ViewGallery metaData={metaData} />
          )}

          {type === "project-update-item-error" && (
            <ProjectUpdateItemError metaData={metaData} />
          )}

          {type === "sleeping-info" && <SleepingInfo metaData={metaData} />}

          {type === "sectional-drawings" && <SectionalDrawings metaData={metaData} />}

          {type === "send-payment-form" && (
            <SendPaymentForm metaData={metaData} />
          )}

          {type === "delete-project" && (
            <DeleteProject metaData={metaData} close={this.closeModal} />
          )}

          {type === "resale-form-instructions" && (
            <ResaleFormInstructions metaData={metaData} close={this.closeModal} />
          )}

          {type === "resale-form-instructions-for-retail" && (
            <ResaleFormInstructionsForRetail metaData={metaData} close={this.closeModal} />
          )}

          {type === "salex-tax-form-instructions" && (
            <SalesTaxInstructions metaData={metaData} close={this.closeModal} />
          )}

          {type === "status-details" && (
            <StatusDetails metaData={metaData} close={this.closeModal} />
          )}

          {type === "trade-access-pending" && (
            <TradeAccessPending metaData={metaData} close={this.closeModal} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.modalsStore.toObject();
}

export default connect(mapStateToProps, mapDispatchToProps(modalActions))(
  Modal
);
