import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Select, { components } from 'react-select';
import { Avatar, Box, Center, Flex, Grid, GridItem, Spacer, Text} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

const HEIGHT = 36;

const styles = {
  container: {
    color: '#B78452',
  },
  icon: {
    cursor: 'pointer',
    marginTop: 20,
  },
  input: {
    height: HEIGHT,
    lineHeight: `${HEIGHT}px`,
    paddingLeft: 10,
    width: 180,
  }
};

export const SearchBar = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');

  const handleChange = (event) => {
    setQuery(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      window.location.href = `/search?search=${query}`;
    }
  }

  return (
    <div style={styles.container}>
      {isOpen ? (
        <input
          autoFocus
          style={styles.input}
          type="text"
          value={query}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <FaSearch style={styles.icon} onClick={() => setIsOpen(true)} />
      )}
    </div>
  );
}

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;
