import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from '@sentry/react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'

import SearchBar from "components/SearchBar";
import Modal from "components/modals/modal";
import LoginLink from "components/products/login-link";
import DetailsLink from "components/products/details-link";
import ShareLink from "components/products/share-link";
import GalleryLink from "components/products/gallery-link";
import { openModal } from "actions/modals";
import { store } from "configure-store";

if (window.gon.environment === 'production') {
  Sentry.init({ dsn: 'https://24f654cf5eb04658ae385d4e6faff93d@o294213.ingest.sentry.io/1540769' });
}

const modal = document.getElementById("global-modal");
const detailLinks = document.querySelectorAll('[data-target="details-link"]');
const shareLinks = document.querySelectorAll('[data-target="share-link"]');
const galleryLinks = document.querySelectorAll('[data-target="gallery-link"]');
const loginLinks = document.querySelectorAll('[data-target="login-link"]');
const searchBar = document.getElementById('global-search-bar');

if (modal) {
  ReactDOM.render(
    <Provider store={store}>
      <Modal />
    </Provider>,
    modal
  );
}

if (loginLinks.length > 0) {
  for (let i = 0; i < loginLinks.length; i++) {
    ReactDOM.render(
      <Provider store={store}>
        <LoginLink
          text={loginLinks[i].getAttribute("data-text")}
          classes={loginLinks[i].getAttribute("data-classes")}
        />
      </Provider>,
      loginLinks[i]
    );
  }
}

if (detailLinks.length > 0) {
  for (let i = 0; i < detailLinks.length; i++) {
    ReactDOM.render(
      <Provider store={store}>
        <DetailsLink />
      </Provider>,
      detailLinks[i]
    );
  }
}

if (shareLinks.length > 0) {
  for (let i = 0; i < shareLinks.length; i++) {
    ReactDOM.render(
      <Provider store={store}>
        <ShareLink />
      </Provider>,
      shareLinks[i]
    );
  }
}

if (galleryLinks.length > 0) {
  for (let i = 0; i < galleryLinks.length; i++) {
    ReactDOM.render(
      <Provider store={store}>
        <GalleryLink />
      </Provider>,
      galleryLinks[i]
    );
  }
}

if (searchBar) {
  const theme = extendTheme({
    styles: {
      global: {
        // styles for the `body`
        body: {
          bg: '#E5E4E1',
        },
      },
    },
  });

   ReactDOM.render(
    <ChakraProvider theme={theme} resetCss={false} disableGlobalStyles cssVarsRoot='global-search-bar'>
      <SearchBar />
    </ChakraProvider>,
    searchBar
  );
}

window.openGlobalModal = (type, opts = {}) => {
  store.dispatch(openModal(type, opts));
};

// Click simulation helpers (for triggering react onClick);
const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
window.simulateMouseClick = (element) => {
  mouseClickEvents.forEach(mouseEventType => element.dispatchEvent(
    new MouseEvent(mouseEventType, {
      view: window,
      bubbles: true,
      cancelable: true,
      buttons: 1
    })
  ));
};
