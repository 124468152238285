import React from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';

import { childrenShape } from '../../lib/shapes';

const useStyles = makeStyles({
  root: {
    fontSize: '0.6875em',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: "Lato, Helvetica, Arial, sans-serif",
    height: 30,
    letterSpacing: '0.1818181818em',
    lineHeight: '2.7272727273em',
    minHeight: '4.1em',
    padding: '0 2em 0',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  primary: {
    backgroundColor: '#BD965F',
    border: 'none',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#CBC4B6'
    }
  },
  secondary: {
    backgroundColor: '#D2CDC8',
    border: '2px solid #BD965F',
    color: '#BD965F',
    '&:hover': {
      backgroundColor: '#CBC4B6'
    }
  },
});

export const Button = props => {
  const classes = useStyles();
  const {
    children,
    className,
    variant,
    ...buttonProps
  } = props;

  return (
    <button {...buttonProps} className={classnames(classes.root, classes[variant], className)}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: childrenShape.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

Button.defaultProps = {
  className: '',
  variant: 'primary'
};

export default Button;
