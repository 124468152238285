import { combineReducers } from "redux";
import configuratorStore from "./configurator-reducers";
import userStore from "./user-reducers";
import projectsStore from "./projects";
import modalsStore from "./modals";
import countryStore from "./countries";

const app = combineReducers({
  configuratorStore,
  userStore,
  projectsStore,
  modalsStore,
  countryStore
});

export default app;
