import { fromJS } from "immutable";

import { FETCHED_COUNTRIES, FETCHED_STATES } from "actions/countries";

const initialState = fromJS({
  countries: [],
  states: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_COUNTRIES:
      return state.set("countries", action.countries);

    case FETCHED_STATES:
      return state.set("states", action.states);
  }

  return state;
};
