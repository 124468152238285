import { SIZE_OPTION_NAMES } from "./constants";

// convert any ids to strings
export function convertIdsToString(obj) {
  Object.keys(obj).forEach(k => {
    let v = obj[k];

    if (v !== null && typeof v === "object") {
      obj[k] = convertIdsToString(v);
    } else if (Array.isArray(v)) {
      obj[k] = v.map(a => {
        if (a !== null && typeof a === "object") {
          return convertIdsToString(a);
        } else {
          return a;
        }
      });
    } else if (k === "id" || k.match(/_id$/)) {
      if (isNumeric(v)) {
        obj[k] = v.toString();
      }
    }
  });

  return obj;
}

function isNumeric(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
}

// convert any nulls to an empty string
export function convertNullsToEmptyString(obj) {
  Object.keys(obj).forEach(k => {
    let v = obj[k];

    if (v !== null && typeof v === "object") {
      obj[k] = convertNullsToEmptyString(v);
    } else if (Array.isArray(v)) {
      obj[k] = v.map(a => {
        if (a !== null && typeof a === "object") {
          return convertNullsToEmptyString(a);
        } else {
          return a;
        }
      });
    } else if (v === null || typeof v === "undefined") {
      obj[k] = "";
    }
  });

  return obj;
}

export function convertKeysToCamelCase(subject) {
  if (Array.isArray(subject)) {
    return subject.map(val => {
      return convertKeysToCamelCase(val);
    });
  } else if (typeof subject === "object" && subject !== null) {
    const newObject = {};
    for (const key in subject) {
      const value = subject[key];
      newObject[snakeToCamel(key)] = convertKeysToCamelCase(value);
    }
    return newObject;
  } else {
    return subject;
  }
}

export function snakeToCamel(s) {
  return s.replace(/(\_\w)/g, m => m[1].toUpperCase());
}

export function csrfToken() {
  let token = document.getElementsByName("csrf-token")[0];

  // Rails will not print this token if in test environment
  if (token) token = token.content;

  return token;
}

export function projectParams(state) {
  const store = state().configuratorStore;
  const variantId = store.getIn(["selectedVariant", "id"]);
  const optionValues = store
    .get("selections")
    .valueSeq()
    .map((optionValue, optionTypeId) => {
      const {
        id,
        customValue,
        upchargeAmount,
        isCustom
      } = optionValue.toObject();
      let p = { id, upcharge_amount: 0, custom_value: null };

      if (isCustom) {
        p.upcharge_amount = upchargeAmount / 100.0;
        p.custom_value = customValue;
      }

      return p;
    })
    .toIndexedSeq();

  const {
    quantityCount,
    customRequest,
    projectName,
    variationImageURL,
    persistVariationImageURL,
    comRequiredYardage,
    comDetailUpdate,
    sideADimension,
    sideBDimension,
    sideCDimension,
    sectionalLayoutIsCustom,
    product,
  } = store.toObject();

  let updatedCustomRequest = customRequest;

  // We don't want to add these notes to one-time products
  const isOneTime = store.get('product').get('master').get('oneTime');
  if (!isOneTime && (sideADimension || sideBDimension || sideCDimension || sectionalLayoutIsCustom)) {
    if (updatedCustomRequest) {
      updatedCustomRequest = `${updatedCustomRequest}

Custom Dimensions Requested:
Side A: ${sideADimension || 'n/a'}
Side B: ${sideBDimension || 'n/a'}
Side C: ${sideCDimension || 'n/a'}
`;
    } else {
      updatedCustomRequest = `Custom Dimensions Requested:
Side A: ${sideADimension || 'n/a'}
Side B: ${sideBDimension || 'n/a'}
Side C: ${sideCDimension || 'n/a'}
`;
    }
  }

  // ADD dimensions, if there are any
  let params = {
    name: projectName,
    line_item: {
      quantity: quantityCount,
      variant_id: variantId,
      options: {
        attributes: {
          custom_request: updatedCustomRequest,
          com_required_yardage: comRequiredYardage
        },
        option_values: optionValues
      },
      com_detail_attributes: comDetailUpdate,
    }
  };

  // We only want to persist the variationImageURL if it's valid
  // and not being used as a placeholder because a particular configuration
  // did not have its own variation image.
  if (persistVariationImageURL) {
    params.line_item.options.attributes.assign_configured_image = getVariationImageURLFileName(
      variationImageURL
    );
  }

  return params;
}

export function getDimension(
  dimensionName,
  defaultValue,
  selectedOptions = []
) {
  const optionValue = selectedOptions.find(s => {
    const selection = s.get("optionType").toLowerCase();
    return selection === dimensionName;
  });

  if (!optionValue) return defaultValue;

  const { displayName, customValue } = optionValue.toObject();

  if (displayName === "Custom") {
    return customValue;
  } else {
    return defaultValue;
  }
}

export function getSelectionAttribute(selection, optionValues, attribute) {
  if (!selection || selection === 0) return null;

  const ov = optionValues.find(o => {
    return o.get("id") === selection;
  });

  if (!ov) return null;

  return ov.get(attribute);
}

export function hideElementsForMobileConfigurator() {
  // Add class to PDP elements that should hide on Configurator display
  $('[data-target="hide-on-configurator-start"]').addClass("desktop-only");
  $(".carousel-wrapper").addClass("hide-on-config-start-mobile");
  $(".top-padding").addClass("hide-on-config-start-mobile");
}

export function getCoordinatesRelativeToDocument(el) {
  const box = el.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

export function isSizeOption(selection) {
  return SIZE_OPTION_NAMES.indexOf(selection) > -1;
}

export function getSizeOptions(selections) {
  return selections.filter(option => {
    if (SIZE_OPTION_NAMES.includes(option.get("optionType"))) {
      return option;
    }
  });
}

export function addImgixParams(url, params = {}) {
  url = `${url}?`;

  const paramString = Object.keys(params)
    .reduce((paramArray, key) => {
      paramArray.push(`${key}=${params[key]}`);
      return paramArray;
    }, [])
    .join("&");

  url = `${url}${paramString}`;

  return url;
}

// If there are no current selections that have this optionValueId as a restricted selection
// then the option is selectable
export function isSelectable(
  selections,
  optionValueRestrictions,
  optionValueId
) {
  return (
    selections.filter(s => {
      const sId = s.get("id");
      const restrictionsForSelection = optionValueRestrictions.get(sId);

      if (!restrictionsForSelection) {
        return false;
      }

      return restrictionsForSelection.indexOf(optionValueId) > -1;
    }).size === 0
  );
}

export function stripZeros(str) {
  if (!str) return "";
  return str.replace(/\.0+$/, "");
}

function getVariationImageURLFileName(url) {
  // We only care about the filename as we'll be accessing this url
  // via Paperclip attachment methods
  return url.replace(/^.+configured_product_images\/.+\//, "");
}

export function waitForScript(scriptName, func) {
  if (isTestENV()) {
    return;
  }

  if (window[scriptName]) {
    func();
  } else {
    setTimeout(() => {
      func(func);
    }, 1000);
  }
}

export function capitalize(string) {
  if (!string) {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shouldOptionRowUpdate(newProps, newState, component) {
  return (
    newProps.selection !== component.props.selection ||
    newState.isOpen !== component.state.isOpen ||
    newState.showAdditionalOptions !== component.state.showAdditionalOptions
  );
}

export function updateSliderOnOpenToggle(oldState, component) {
  if (!oldState.isOpen && component.state.isOpen) {
    component.refs.slider.slickGoTo(component.refs.slider.props.slickGoTo);
  }
}

export function numberFormat() {
  const opts = {
    style: "decimal",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2
  };

  if (global.Intl) {
    return new Intl.NumberFormat("en-us", opts);
  } else {
    return new IntlPackage.NumberFormat("en-us", opts);
  }
}

export function formatUpchargeAmount(amount) {
  // Return given amount if it already starts with '+' or '-'
  if (/^-/.test(amount) || /^\+/.test(amount)) {
    return amount;
  } else {
    return `+${amount}`;
  }
}

export function getDisplayPriceOfSizeSelection(selection, selectedVariant) {
  const sizePrice =
    selection && selection.get("isCustom")
      ? selection.get("displayCustomValuePrice")
      : selectedVariant.get("displayPrice");

  let displayPrice;

  if (sizePrice) {
    displayPrice = sizePrice;
  }

  return displayPrice;
}
