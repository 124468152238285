import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Component from "components/component";
import mapDispatchToProps from "lib/map-dispatch-to-props";
import * as modalActions from "actions/modals";

export class GalleryLink extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired
  };

  openModal = e => {
    e.preventDefault();
    this.props.actions.modalActions.openModal("view-gallery");
  };

  render() {
    return <a onClick={this.openModal}>Image Gallery</a>;
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps(modalActions))(
  GalleryLink
);
