import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Component from "components/component";
import mapDispatchToProps from "lib/map-dispatch-to-props";
import * as modalActions from "actions/modals";

export class LoginLink extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    classes: PropTypes.string,
    text: PropTypes.string
  };

  openModal = e => {
    e.preventDefault();
    this.props.actions.modalActions.openModal("login");
  };

  render() {
    const { text, classes } = this.props;

    return (
      <a className={classes} onClick={this.openModal}>
        {text}
      </a>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps(modalActions))(
  LoginLink
);
