import request from "axios";
import { fromJS } from "immutable";
import { deserialize } from "../lib/deserializers";
import { csrfToken, projectParams } from "../lib/helpers";
import { projectFetched } from "./configurator-actions";
import { openModal } from "./modals";
import { setProjects } from "./user-actions";

export const name = "projectActions";

export const FETCHING_PROJECT = `${name}_FETCHING_PROJECT`;
export const FETCH_PROJECT_SUCCESS = `${name}_FETCH_PROJECT_SUCCESS`;
export const SET_PROJECT = `${name}_SET_PROJECT`;
export const UPDATING_PROJECT_ITEM = `${name}_UPDATING_PROJECT_ITEM`;
export const UPDATE_PROJECT_ITEM_SUCCESS = `${name}_UPDATE_PROJECT_ITEM_SUCCESS`;
export const UPDATE_PROJECT_ITEM_ERROR = `${name}_UPDATE_PROJECT_ITEM_ERROR`;
export const REMOVE_PROJECT = `${name}_REMOVE_PROJECT`;

export function fetchProject(slug, lineItemId, clientId = null) {
  return function(dispatch, state) {
    dispatch(fetchingProject());

    return request
      .get(`/account/projects/${slug}.json?client_id=${clientId}`)
      .then(response => {
        const project = fromJS(deserialize(response.data));

        dispatch(fetchProjectSuccess());
        dispatch(setProject(project));
        dispatch(projectFetched(project, lineItemId));
      });
  };
}

export function fetchingProject() {
  return {
    type: FETCHING_PROJECT
  };
}

export function fetchProjectSuccess() {
  return {
    type: FETCH_PROJECT_SUCCESS
  };
}

export function setProject(data) {
  return {
    type: SET_PROJECT,
    data
  };
}

export function updateProjectItem(lineItemId, clientId = null) {
  return function(dispatch, state) {
    dispatch(updatingProjectItem());

    const id = state().projectsStore.getIn(["project", "id"]);
    let params = projectParams(state);

    params.client_id = clientId;

    return request
      .put(
        `/projects/${id}/line_items/${lineItemId}/update_item.json`,
        params,
        { headers: { "X-CSRF-Token": csrfToken() } }
      )
      .then(response => {
        dispatch(updateProjectItemSuccess());
        dispatch(setProject(fromJS(deserialize(response.data))));

        const { name, url } = response.data;
        dispatch(openModal("project-update-item-success", { name, url }));
      })
      .catch(e => {
        dispatch(updateProjectItemError());
        dispatch(
          openModal("project-update-item-error", {
            error: e.response.data.error
          })
        );
      });
  };
}

export function updatingProjectItem() {
  return {
    type: UPDATING_PROJECT_ITEM
  };
}

export function updateProjectItemSuccess() {
  return {
    type: UPDATE_PROJECT_ITEM_SUCCESS
  };
}

export function updateProjectItemError() {
  return {
    type: UPDATE_PROJECT_ITEM_ERROR
  };
}

export function removeProject(id) {
  return function(dispatch, state) {
    let projects = state().userStore.get("projects");
    const index = projects.findIndex(p => p.get("id") === id);

    projects = projects.remove(index);

    dispatch(setProjects(projects));
  };
}
