export function getProductImageName(state) {
  const product = state.get("product");

  if (product.isEmpty()) {
    return "";
  }

  const selections = state.get("selections");
  const someSelectionsDontHaveAvailableVariationImages = selections.some(s => {
    return !s.get("variationImageAvailable");
  });

  // If a selection's option type is supposed to have images
  // but we don't have an available variation image for this option
  // than we don't return any variation image.
  // Ex. Fabrics have images but not non-core fabrics
  if (someSelectionsDontHaveAvailableVariationImages) {
    return "";
  }

  const { slug } = product.toObject();
  const selectionsWithImages = selections.filter(s => {
    return s.get("optionTypeHasImages") && !s.get("skipVariationImage");
  });
  const selectionSlugs = selectionsWithImages
    .toList()
    .filter(v => !v.isEmpty())
    .map(s => `_${s.get("slug")}`)
    .join("");

  return `${slug}${selectionSlugs}`;
}

export function getEstimatedTotal(state, optionId) {
  const selectedVariant = state.configuratorStore.get("selectedVariant");
  const selections = state.configuratorStore.get("selections");
  const variantPrice = selectedVariant.get("price");

  return calculateEstimatedTotal(variantPrice, selections);;
}

// START_HERE: where does the custom width upcharge calculation live?
export function calculateEstimatedTotal(variantPrice, selections) {
  const selectionsTotal = selections.reduce((total, selection) => {
    const { upchargeAmount, variant } = selection.toObject();

    // If a variant is associated with this optionValue
    // it will be part of the base price, so don't add it to total
    if (variant) return total;

    return total + upchargeAmount;
  }, 0);

  return (variantPrice + selectionsTotal) / 100.0;
}
