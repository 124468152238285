import React from "react";
import axios from "axios";
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from "react-redux";

import Component from "components/component";
import { csrfToken } from "lib/helpers";

export class ProductInquiryModal extends Component {
  static propTypes = {
    product: ImmutablePropTypes.map.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      submitting: false,
      success: false,
      errors: {}
    };
  }

  handleChange = (field, e) => {
    let state = {};
    state[field] = e.target.value;

    this.setState(state);
  };

  submitInquiry = e => {
    e.preventDefault();

    this.setState(
      {
        errors: {},
        submitting: true
      },
      () => {
        const slug = this.props.product.get("slug");
        const url = `/products/${slug}/inquire`;
        const params = {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
          url: window.location.href
        };

        return axios
          .post(url, params, { headers: { "X-CSRF-Token": csrfToken() } })
          .then(response => {
            this.setState({
              success: true,
              submitting: false
            });
          })
          .catch(e => {
            const errors = e.response.data.errors || {
              general: "There was an error with your request"
            };

            this.setState({
              errors,
              submitting: false
            });
          });
      }
    );
  };

  render() {
    const { name, email, message, submitting, success, errors } = this.state;
    const productName = this.props.product.get("name");
    const placeholder = `Hi, I'm interested learning more about the ${productName}. Please contact me with more information.`;

    return (
      <div className="product-inquiry-wrapper">
        <h2 className="headline">Product Inquiry</h2>
        {!success && (
          <div className="inquiry-form">
            <div className="error-notice">{errors.general}</div>

            <form>
              <div className="grid-row">
                <div className="col-6-12">
                  <div className="label-holder">
                    <label>Name*</label>
                  </div>

                  <input
                    type="text"
                    onChange={this.handleChange.bind(this, "name")}
                    value={name}
                    className={errors.name ? "error text-input" : "text-input"}
                  />

                  <div className="error-notice">{errors.name}</div>
                </div>

                <div className="col-6-12">
                  <div className="label-holder">
                    <label>Email*</label>
                  </div>

                  <input
                    type="email"
                    onChange={this.handleChange.bind(this, "email")}
                    value={email}
                    className={errors.email ? "error text-input" : "text-input"}
                  />

                  <div className="error-notice">{errors.email}</div>
                </div>
              </div>

              <div className="grid-row">
                <div className="col-12-12">
                  <div className="label-holder">
                    <label>Message*</label>
                  </div>

                  <textarea
                    onChange={this.handleChange.bind(this, "message")}
                    placeholder={placeholder}
                    value={message}
                    className={
                      errors.message ? "error text-input" : "text-input"
                    }
                  />

                  <div className="error-notice">{errors.message}</div>
                </div>
              </div>

              <div className="grid-row">
                <div className="col-6-12">
                  <p className="required-field-note">* Required field</p>
                </div>
                <div className="col-6-12 push-right">
                  <input
                    type="submit"
                    className="large right"
                    disabled={submitting}
                    value={submitting ? "Submitting..." : "Inquire"}
                    onClick={this.submitInquiry}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        {success && (
          <div className="inquiry-success-message">
            <p>Thanks for your message!</p>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const product = state.configuratorStore.get("product");

  return {
    product
  };
}

export default connect(mapStateToProps)(ProductInquiryModal);
