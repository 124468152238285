import axios from "axios";
import { deserialize } from "lib/deserializers";

import { getUrl } from "./api";

export const findById = (id) => {
  const url = getUrl(`/products/${id}.json`);
  return axios.get(url).then(response => {
    return deserialize(response.data);
  });
};

export default findById;
