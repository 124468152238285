import React, { useState } from "react";
import Select from "react-select";

const ulStyles = {
  listStyleType: 'disc',
};

const liStyles = {
  paddingLeft: 0,
};

const h2Styles = {
  width: '65%',
};

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "DC",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Nebraska",
  "Nevada",
  "New Jersey",
  "New Mexico",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

const SalesTaxInstructions = () => {
  const [selectedState, setSelectedState] = useState("");

  const setState = (selection) => {
    setSelectedState(selection.value);
  }

  return (
    <div className="sales-tax-form-instructions">
      <h2 className="headline" style={h2Styles}>Sales Tax Permit Example</h2>
      <div className="modal-content">
        <Select
          id="account_holder_type"
          className="form-control required"
          placeholder="Select State"
          searchable
          options={states.map(state => {
            return { label: state, value: state }
          })}
          onChange={setState}
          value={selectedState}
        />
        <a
          href={`/download/sales_tax_permit?state=${selectedState}`}
          target="_blank"
          className={`large button download  ${selectedState ? '' : 'disabled'}`}
        >
          Download
        </a>
      </div>
    </div>
  );
};

export default SalesTaxInstructions;
