import { Map, List } from "immutable";
import { bindActionCreators } from "redux";

/*
Takes a list of action objects like:

[
 {
  name: 'exampleActions',
  someAction: function(){}
 },
 {
  name: 'otherActions',
  someAction: function(){}
 }
]

and converts it to a map of bound actions
(bound actions meaning you don't have to call 'dispatch' when invoking actions,
they're already bound to dispatch):

 {
  exampleActions: {
   someAction: function(){}
  }

 otherActions: {
   someAction: function(){}
 }
}
 */
export default function mapDispatchToProps(...actionObjects) {
  return function(dispatch) {
    const boundActionObjects = List(actionObjects).reduce((p, actionObject) => {
      const name = actionObject.name;
      const actionObjectWithBoundFunctions = bindActionCreators(
        Map(actionObject)
          .filter(value => typeof value === "function")
          .toJS(),
        dispatch
      );
      p[name] = actionObjectWithBoundFunctions;
      return p;
    }, {});

    return {
      actions: boundActionObjects,
      dispatch
    };
  };
}
