import React from "react";
import PropTypes from "prop-types";
import Component from "components/component";

export default class ProjectItemUpdateSuccess extends Component {
  static propTypes = {
    metaData: PropTypes.object
  };

  render() {
    const { name, url } = this.props.metaData;

    return (
      <div id="project-item-update-success">
        <p>This item has been updated successfully.</p>
        <p>
          <a href={url}>Return to the {name} project page</a>
        </p>
      </div>
    );
  }
}
