import React from "react";
import Component from "components/component";
import axios from "axios";
import { csrfToken } from "../../lib/helpers";

export default class SharePDPModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEmail: gon.userEmail,
      toEmail: "",
      message: "",
      success: false,
      errors: {},
      sending: false
    };
  }

  handleChange = (field, e) => {
    let state = {};
    state[field] = e.target.value;

    this.setState(state);
  };

  sharePDP = e => {
    e.preventDefault();

    this.setState(
      {
        errors: {},
        sending: true
      },
      () => {
        const body = {
          from_email: this.state.fromEmail,
          to_email: this.state.toEmail,
          message: this.state.message,
          url: window.location.href
        };

        return axios
          .post("/products/share", body,
            { headers: { "X-CSRF-Token": csrfToken() } })
          .then(response => {
            if (response.data.errors) {
              this.setState({
                errors: response.data.errors,
                sending: false
              });
            } else if (response.data.status === 200) {
              this.setState({
                success: true,
                sending: false
              });
            }
          });
      }
    );
  };

  render() {
    const {
      fromEmail,
      toEmail,
      message,
      success,
      errors,
      sending
    } = this.state;

    return (
      <div>
        <h2 className="headline">Share</h2>
        {!success && (
          <div className="share-form-wrapper" id="share-form-wrapper">
            <form id="share-form">
              <div className="grid-row">
                <div className="col-6-12">
                  <div className="label-holder">
                    <label>Your Email*</label>
                  </div>

                  <input
                    type="text"
                    id="display_from_field"
                    value={fromEmail}
                    placeholder="Your Email"
                    onChange={this.handleChange.bind(this, "fromEmail")}
                    className={
                      errors.from_email ? "error text-input" : "text-input"
                    }
                  />

                  <div className="error-notice">{errors.from_email}</div>
                </div>

                <div className="col-6-12">
                  <div className="label-holder">
                    <label>Recipient Email*</label>
                  </div>

                  <input
                    type="email"
                    placeholder="Recipient Email"
                    onChange={this.handleChange.bind(this, "toEmail")}
                    value={toEmail}
                    className={
                      errors.to_email ? "error text-input" : "text-input"
                    }
                  />

                  <div className="error-notice">{errors.to_email}</div>
                </div>
              </div>

              <div className="grid-row">
                <div className="col-12-12">
                  <div className="label-holder">
                    <label>Message</label>
                  </div>

                  <textarea
                    onChange={this.handleChange.bind(this, "message")}
                    value={message}
                  />
                </div>
              </div>

              <div className="grid-row">
                <div className="col-6-12">
                  <p className="required-field-note">* Required field</p>
                </div>
                <div className="col-6-12 push-right">
                  <input
                    type="submit"
                    className="large right"
                    value={sending ? "Sending..." : "Send"}
                    onClick={this.sharePDP}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        {success && (
          <div className="share-success-message">
            <p>Message Sent</p>
          </div>
        )}
      </div>
    );
  }
}
