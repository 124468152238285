export default {
  apiBase: getConfig("apiBase"),
};

function getConfig(key) {
  if (typeof window === "undefined") {
    return "";
  }
  if (!window || !window._appConfig) return "";
  return window._appConfig[key] || "";
}
