import { fromJS } from "immutable";

import {
  SET_USER,
  SET_USER_ROLE,
  SET_TRADE_ACCESS_PENDING,
  SET_USER_DATA,
  SET_PROJECTS,
  SET_ORDERS,
  FETCHING_PROJECTS,
  FETCHING_ORDERS,
  FETCHING_USER_DATA,
  SET_EDITING_USER_DATA,
  CANCEL_EDITING_USER_DATA,
  EDIT_USER_DATA_FIELD,
  EDIT_USER_DATA_ADDRESS_FIELD,
  SET_UPDATING_USER_DATA,
  SET_CHANGING_PASSWORD,
  SET_ERRORS,
  FETCHED_BANK_ACCOUNTS
} from "actions/user-actions";

const initialState = fromJS({
  userId: "",
  userRole: "",
  fetchingUserData: false,
  fetchingProjects: false,
  fetchingOrders: false,
  fetchingBankAccounts: false,
  orders: [],
  projects: [],
  userData: {
    firstname: "",
    lastname: "",
    company: "",
    websiteUrl: "",
    email: "",
    newPassword: "",
    passwordConfirmation: "",
    companyAddress: {
      address1: "",
      address2: "",
      city: "",
      zipcode: "",
      state: {},
      country: {},
      company: "",
      stateId: "",
      stateName: "",
      countryId: ""
    }
  },
  editingUserData: false,
  updatingUserData: false,
  changingPassword: false,
  errors: {
    company_address: {}
  },
  bankAccounts: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return state.set("userId", action.userId);

    case SET_USER_ROLE:
      return state.set("userRole", action.userRole);

    case SET_TRADE_ACCESS_PENDING:
      return state.set("tradeAccessPending", action.tradeAccessPending);

    case FETCHING_PROJECTS:
      return state.set("fetchingProjects", true);

    case SET_PROJECTS:
      return state
        .set("fetchingProjects", false)
        .set("projects", fromJS(action.projects));

    case FETCHING_ORDERS:
      return state.set("fetchingOrders", true);

    case SET_ORDERS:
      return state
        .set("fetchingOrders", false)
        .set("orders", fromJS(action.orders));

    case FETCHING_USER_DATA:
      return state.set("fetchingUserData", true);

    case SET_USER_DATA:
      return state
        .set("fetchingUserData", false)
        .set("userData", fromJS(action.userData));

    case SET_EDITING_USER_DATA:
      return state.set("editingUserData", true);

    case CANCEL_EDITING_USER_DATA:
      return state
        .set("editingUserData", false)
        .set("updatingUserData", false)
        .set("changingPassword", false);

    case EDIT_USER_DATA_FIELD:
      return state.setIn(["userData", action.field], action.value);

    case EDIT_USER_DATA_ADDRESS_FIELD:
      return state.setIn(
        ["userData", "companyAddress", action.field],
        action.value
      );

    case SET_UPDATING_USER_DATA:
      return state.set("updatingUserData", true);

    case SET_CHANGING_PASSWORD:
      return state.set("changingPassword", true);

    case SET_ERRORS:
      return state.set("errors", action.errors);

    case FETCHED_BANK_ACCOUNTS:
      return state
        .set("fetchingBankAccounts", false)
        .set("bankAccounts", action.accounts);
  }

  return state;
};
