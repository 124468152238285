import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from "react-redux";

import Component from "components/component";
import mapDispatchToProps from "lib/map-dispatch-to-props";
import * as configuratorActions from "actions/configurator-actions";
import * as userActions from "actions/user-actions";
import * as projectActions from "actions/projects";
import * as modalActions from "actions/modals";
import { addImgixParams } from "lib/helpers";

export class ChooseProjectsModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    addingItemToExistingProject: PropTypes.bool.isRequired,
    creatingProject: PropTypes.bool.isRequired,
    fetchingProjects: PropTypes.bool.isRequired,
    projectName: PropTypes.string,
    projects: ImmutablePropTypes.list,
    variationImageURL: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { errors: [], projectId: "" };
  }

  UNSAFE_componentWillMount() {
    const { actions: { userActions } } = this.props;
    userActions.fetchUserProjects("modifiable");
  }

  componentWillUnmount() {
    // Clear value of new project name on unmount
    // TODO: This should just be in local state
    this.props.actions.configuratorActions.updateNewProjectName("");
  }

  onSelect = (selection) => {
    $(".Select-placeholder").text(selection.label);
    this.setState({ projectId: selection.value });
  }

  updateNewProjectName = e => {
    this.props.actions.configuratorActions.updateNewProjectName(e.target.value);
  };

  createOrAddToProject = e => {
    e.preventDefault();

    this.setState(
      {
        errors: []
      },
      () => {
        if (this.state['projectId']) {
          this.addToProject();
        } else {
          this.createProject();
        }
      }
    );
  }

  addToProject = () => {
    const errorCallback = errors => {
      this.setState({ errors });
    };

    this.props.actions.configuratorActions
      .addItemToExistingProject(this.state["projectId"], errorCallback)
      .then(response => {
        if (response.errors) {
          this.setState({ errors: response.errors });
        } else {
          window.location.href = `/account/projects/${response.slug}`;
        }
      });
  }

  createProject = () => {
    this.props.actions.configuratorActions
      .createProject()
      .then(response => {
        if (response.errors) {
          this.setState({ errors: response.errors });
        } else {
          window.location.href = `/account/projects/${response.slug}`;
        }
      });
  };

  render() {
    const { errors, projectId } = this.state;
    const {
      fetchingProjects,
      projectName,
      projects,
      creatingProject,
      addingItemToExistingProject,
      variationImageURL
    } = this.props;

    const updating = creatingProject || addingItemToExistingProject;
    const formattedProjects = projects
      .valueSeq()
      .map(project => {
        return { value: project.get("id"), label: project.get("name") };
      })
      .toArray();

    return (
      <div className="inner-wrapper modal-wrapper">
        <div className="image-holder mobile-only">
          <img src={addImgixParams(variationImageURL, { w: 1200 })} />
        </div>

        <div className="projects-holder left-modal-inner">
          <h2 className="headline">
            <span>&nbsp;</span>
          </h2>

          {fetchingProjects && <p>Fetching projects...</p>}

          {!fetchingProjects && (
            <div className="projects-select-holder">
              <form onSubmit={this.createOrAddToProject}>
                <label>CREATE NEW QUOTE</label>
                <input
                  type="text"
                  id="project_name_field"
                  value={projectName}
                  placeholder="Enter Quote Name"
                  onChange={this.updateNewProjectName}
                />

                {formattedProjects.length > 0 && (
                  <div>
                    <p className="lined-title">
                      <span>or</span>
                    </p>

                    <label>ADD TO AN EXISTING QUOTE</label>
                    <Select
                      placeholder="Select"
                      options={formattedProjects}
                      defaultValue="Select"
                      isClearable
                      isSearchable={false}
                      onChange={this.onSelect}
                    />
                  </div>
                )}

                <button
                  disabled={(!projectName && !projectId) || updating}
                  className="large right assign_to_quote cta-button"
                >
                  {creatingProject ? "Saving..." : "Save Quote"}
                </button>
              </form>
            </div>
          )}

          {errors.length > 0 && (
            <div className="choose-project-errors">
              <p>There was an error with your submission:</p>
              <ul>{errors.map(e => <li key={e}>{e}</li>)}</ul>
            </div>
          )}
        </div>
        <div className="right-modal-inner desktop-only" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    addingItemToExistingProject,
    creatingProject,
    projectName,
    variationImageURL
  } = state.configuratorStore.toObject();

  const { projects, fetchingProjects } = state.userStore.toObject();

  return {
    addingItemToExistingProject,
    projects,
    fetchingProjects,
    creatingProject,
    projectName,
    variationImageURL
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps(
    configuratorActions,
    modalActions,
    userActions,
    projectActions
  )
)(ChooseProjectsModal);
