import request from "axios";
import { fromJS } from "immutable";

import * as Order from "model/order";
import * as User from "model/user";
import { csrfToken } from "lib/helpers";
import { deserialize } from "lib/deserializers";

export const name = "userActions";
export const SET_USER = `${name}_SET_USER`;
export const SET_USER_ROLE = `${name}_SET_USER_ROLE`;
export const SET_TRADE_ACCESS_PENDING = `${name}_SET_TRADE_ACCESS_PENDING`;
export const FETCHING_PROJECTS = `${name}_FETCHING_PROJECTS`;
export const SET_PROJECTS = `${name}_SET_PROJECTS`;
export const FETCHING_ORDERS = `${name}_FETCHING_ORDERS`;
export const SET_ORDERS = `${name}_SET_ORDERS`;
export const FETCH_USER_DATA = `${name}_FETCH_USER_DATA`;
export const FETCHING_USER_DATA = `${name}_FETCHING_USER_DATA`;
export const SET_USER_DATA = `${name}_SET_USER_DATA`;
export const SET_EDITING_USER_DATA = `${name}_SET_EDITING_USER_DATA`;
export const CANCEL_EDITING_USER_DATA = `${name}_CANCEL_EDITING_USER_DATA`;
export const EDIT_USER_DATA_FIELD = `${name}_EDIT_USER_DATA_FIELD`;
export const EDIT_USER_DATA_ADDRESS_FIELD = `${name}_EDIT_USER_DATA_ADDRESS_FIELD`;
export const UPDATE_USER_DATA = `${name}_UPDATE_USER_DATA`;
export const SET_UPDATING_USER_DATA = `${name}_SET_UPDATING_USER_DATA`;
export const SET_CHANGING_PASSWORD = `${name}_SET_CHANGING_PASSWORD`;
export const SET_ERRORS = `${name}_SET_ERRORS`;
export const FETCH_BANK_ACCOUNTS = `${name}_FETCH_BANK_ACCOUNTS`;
export const FETCHED_BANK_ACCOUNTS = `${name}_FETCHED_BANK_ACCOUNTS`;

export function setUser(userId) {
  return {
    type: SET_USER,
    userId
  };
}
export function setUserRole(userRole) {
  return {
    type: SET_USER_ROLE,
    userRole
  };
}
export function setTradeAccessPending(tradeAccessPending) {
  return {
    type: SET_TRADE_ACCESS_PENDING,
    tradeAccessPending
  };
}

export function fetchUserProjects(filter = null) {
  return function(dispatch, state) {
    dispatch(fetchingProjects());

    let url = "/projects";

    if (filter) {
      url += `?filter=${filter}`;
    }

    return request.get(url).then(response => {
      const projects = deserialize(response.data);
      dispatch(setProjects(projects));
      return projects;
    });
  };
}

export function fetchingProjects() {
  return { type: FETCHING_PROJECTS };
}

export function setProjects(projects) {
  return {
    type: SET_PROJECTS,
    projects
  };
}

export function fetchUserOrders() {
  return function(dispatch, state) {
    dispatch(fetchingOrders());

    Order.findByUser().then(orders => {
      dispatch(setOrders(orders));
    });
  };
}

export function fetchingOrders() {
  return { type: FETCHING_ORDERS };
}

export function setOrders(orders) {
  return {
    type: SET_ORDERS,
    orders
  };
}

export function fetchUserData() {
  return function(dispatch, state) {
    dispatch(fetchingUserData());

    User.findCurrentUser().then(userData => {
      dispatch(setUserData(userData));
    });
  };
}

export function fetchingUserData() {
  return { type: FETCHING_USER_DATA };
}

export function setUserData(userData) {
  return {
    type: SET_USER_DATA,
    userData
  };
}

export function setEditingUserData() {
  return { type: SET_EDITING_USER_DATA };
}

export function cancelEditingUserData() {
  return { type: CANCEL_EDITING_USER_DATA };
}

export function editUserDataField(field, value) {
  return {
    type: EDIT_USER_DATA_FIELD,
    field,
    value
  };
}

export function editUserDataAddressField(field, value) {
  return {
    type: EDIT_USER_DATA_ADDRESS_FIELD,
    field,
    value
  };
}

export function updateUserData(userData) {
  return function(dispatch, state) {
    dispatch(setUpdatingUserData());

    const params = {
      firstname: userData.firstname,
      lastname: userData.lastname,
      website_url: userData.websiteUrl,
      email: userData.email,
      password: userData.newPassword,
      password_confirmation: userData.passwordConfirmation,
      company_address_attributes: {
        firstname: userData.firstname,
        lastname: userData.lastname,
        company: userData.companyAddress.company,
        address1: userData.companyAddress.address1,
        address2: userData.companyAddress.address2,
        phone: userData.companyAddress.phone,
        city: userData.companyAddress.city,
        zipcode: userData.companyAddress.zipcode,
        state_id: userData.companyAddress.stateId,
        country_id: userData.companyAddress.countryId
      }
    };

    return request
      .put(
        "/account/profile/update",
        { user: params },
        { headers: { "X_CSRF-Token": csrfToken() } }
      )
      .then(response => {
        dispatch(setUserData(deserialize(response.data)));
        dispatch(cancelEditingUserData());
        return response.data;
      })
      .catch(e => {
        dispatch(setErrors(fromJS(e.response.data.errors)));
      });
  };
}

export function setUpdatingUserData() {
  return { type: SET_UPDATING_USER_DATA };
}

export function setChangingPassword() {
  return { type: SET_CHANGING_PASSWORD };
}

export function setErrors(errors) {
  return {
    type: SET_ERRORS,
    errors
  };
}

export function fetchBankAccounts() {
  return function(dispatch, state) {
    return request.get("/bank-accounts").then(response => {
      const accounts = fromJS(deserialize(response.data.bank_accounts));
      dispatch(fetchedBankAccounts(accounts));
    });
  };
}

export function fetchedBankAccounts(accounts) {
  return {
    type: FETCHED_BANK_ACCOUNTS,
    accounts
  };
}

export function verifyBankAccount(id, payment1, payment2) {
  return function(dispatch, state) {
    return request
      .post(
        `/bank-accounts/${id}/verify`,
        { payment_1: payment1, payment_2: payment2 },
        { headers: { "X_CSRF-Token": csrfToken() } }
      )
      .then(response => {
        return response.data;
      })
      .catch(e => {
        return e.response.data;
      });
  };
}
