import React from "react";
import PropTypes from "prop-types";
import request from "axios";

import Component from "components/component";
import { csrfToken } from "lib/helpers";

export default class DeleteProject extends Component {
  static propTypes = {
    metaData: PropTypes.object,
    close: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      destroying: false,
      error: null,
      success: false
    };
  }

  onSubmit = e => {
    e.preventDefault();

    const id = this.props.metaData.project.get("id");

    this.setState(
      {
        destroying: true,
        error: null
      },
      () => {
        request
          .delete(`/projects/${id}.json`, {
            headers: { "X-CSRF-Token": csrfToken() }
          })
          .then(res => {
            if (res.status === 200) {
              this.setState(
                {
                  destroying: false,
                  success: true
                },
                () => {
                  this.props.metaData.removeProject(id);
                }
              );
            }
          })
          .catch(err => {
            if (err.response.status === 400) {
              this.setState({
                destroying: false,
                error:
                  "This project is currently in your checkout and cannot be deleted. Please visit the checkout to cancel your checkout."
              });

              return;
            }

            this.setState({
              destroying: false,
              error:
                "There was an error deleting this project. Please try again."
            });
          });
      }
    );
  };

  render() {
    const { name } = this.props.metaData.project.toObject();
    const { destroying, error, success } = this.state;

    if (success) {
      return (
        <div className="delete-project-success">
          <p className="subheadline">
            Successfully deleted the "{name}" Project.
          </p>
        </div>
      );
    }

    return (
      <div>
        <p className="subheadline">
          Are you sure you want to delete the project "{name}"?
        </p>

        <div className="buttons-wrapper">
          <form onSubmit={this.onSubmit} action="#" className="left-button">
            <button className="large" disabled={destroying}>
              {destroying ? "Deleting..." : "Delete"}
            </button>
          </form>

          <a
            href="#"
            className="button large right-button"
            onClick={this.props.close}
          >
            Cancel
          </a>
        </div>

        {error && <p className="error">{error}</p>}
      </div>
    );
  }
}
