import React from "react";
import Component from "components/component";

export default class WhatsThisModal extends Component {
  render() {
    return (
      <div className="whats-this-wrapper">
        <h2 className="headline">Quotes</h2>
        <div className="modal-content">
          <p className="modal-text">
            As a trade member, assigning items to a Quote allows you to
            build your own quotes and checkout online.
          </p>
        </div>
        <svg
          width="379px"
          height="50px"
          viewBox="0 0 379 50"
          version="1.1"
          xmlns="//www.w3.org/2000/svg"
        >
          <title>Group 6</title>
          <desc>Created with Sketch.</desc>
          <defs />
          <g
            id="Product"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="DCO_Product---Sofa---Whats-This-Overlay"
              transform="translate(-446.000000, -5830.000000)"
            >
              <g id="Group-7" transform="translate(0.000000, -620.000000)">
                <g id="Group-6" transform="translate(446.000000, 6450.000000)">
                  <g>
                    <g id="Group-2" transform="translate(38.000000, 1.000000)">
                      <path
                        d="M4,4 L306.5,4"
                        id="Line"
                        stroke="#4A494C"
                        strokeLinecap="square"
                      />
                      <circle
                        id="Oval"
                        fill="#4A494C"
                        cx="3.5"
                        cy="3.5"
                        r="3.5"
                      />
                      <circle
                        id="Oval-Copy"
                        fill="#4A494C"
                        cx="155.5"
                        cy="3.5"
                        r="3.5"
                      />
                      <circle
                        id="Oval"
                        fill="#4A494C"
                        cx="306.5"
                        cy="3.5"
                        r="3.5"
                      />
                    </g>
                    <text
                      id="ADD-TO-PROJECT-QUOTE"
                      fill="#4A494C"
                      fontFamily="Lato-Regular, Lato"
                      fontSize="11"
                      fontWeight="normal"
                      letterSpacing="1.5"
                    >
                      <tspan x="168.051" y="31">
                        ADD ITEMS
                      </tspan>
                      <tspan x="139.4095" y="47">
                        TO QUOTE
                      </tspan>
                    </text>
                    <text
                      id="CHECKOUT"
                      fill="#4A494C"
                      opacity="0.501250029"
                      fontFamily="Lato-Regular, Lato"
                      fontSize="11"
                      fontWeight="normal"
                      letterSpacing="1.5"
                    >
                      <tspan x="308.2995" y="39">
                        CHECKOUT
                      </tspan>
                    </text>
                    <text
                      id="SHOP-COLLECTION"
                      fill="#4A494C"
                      opacity="0.501250029"
                      fontFamily="Lato-Regular, Lato"
                      fontSize="11"
                      fontWeight="normal"
                      letterSpacing="1.5"
                    >
                      <tspan x="24.1775" y="31">
                        SHOP
                      </tspan>
                      <tspan x="0.246" y="47">
                        COLLECTION
                      </tspan>
                    </text>
                    <path
                      d="M113.520815,1.8137085 L118.156854,1.8137085 L118.156854,6.44974747 L113.520815,1.8137085 Z"
                      id="Rectangle-6"
                      stroke="#333333"
                      transform="translate(115.485281, 4.485281) rotate(-315.000000) translate(-115.485281, -4.485281) "
                    />
                    <path
                      d="M265.520815,1.8137085 L270.156854,1.8137085 L270.156854,6.44974747 L265.520815,1.8137085 Z"
                      id="Rectangle-6-Copy"
                      stroke="#333333"
                      transform="translate(267.485281, 4.485281) rotate(-315.000000) translate(-267.485281, -4.485281) "
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
