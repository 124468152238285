import axios from "axios";
import { getUrl, transformResponse } from "./api";

export const findByUser = () => {
  const url = getUrl(`/orders`);
  return axios.get(url).then(response => {
    return transformResponse(response.data);
  });
};

export default findByUser;
