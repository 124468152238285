import React from "react";
import PropTypes from "prop-types";
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from "react-redux";

import Component from "components/component";
import mapDispatchToProps from "lib/map-dispatch-to-props";
import { stripZeros } from "lib/helpers";

export class SleepingInfo extends Component {
  static propTypes = {
    bedSize: PropTypes.string,
    lineDrawingDescription: PropTypes.string,
    lineDrawingUrl: PropTypes.string,
    lineDrawing: ImmutablePropTypes.map,
    productName: PropTypes.string,
    selectedVariant: ImmutablePropTypes.map
  };

  render() {
    const {
      productName,
      selectedVariant,
      bedSize,
      lineDrawingUrl,
      lineDrawingDescription,
      lineDrawing
    } = this.props;

    const { height, depth, width } = selectedVariant.toObject();

    return (
      <div>
        <h1 className="headline">
          Sleeping Info for {productName} - {bedSize}
        </h1>

        <p>{lineDrawingDescription}</p>

        {!lineDrawingUrl && (
          <p>There is not a line drawing for the selected configuration.</p>
        )}

        {lineDrawingUrl && lineDrawing && (
          <div>
            <div className="line-drawing-wrapper">
              <a href={`/line_drawings/${lineDrawing.get('id')}.pdf`} target="_blank">
                <img src={lineDrawingUrl} />
              </a>
            </div>

            <p>
              W{stripZeros(width)}" &nbsp; L{stripZeros(depth)}" &nbsp; H{stripZeros(
                height
              )}"
            </p>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    product,
    selectedVariant,
    selections,
    optionTypes
  } = state.configuratorStore.toObject();
  const productName = product.get("name");
  const lineDrawings = selectedVariant.get("lineDrawings");
  const bedSizeOptionTypeId = optionTypes
    .find(o => o.get("name") === "bed_size")
    .get("id");
  const constructionOptionTypeId = optionTypes
    .find(o => o.get("name") === "bed_construction")
    .get("id");

  const bedSize = selections
    .find(s => {
      return s.get("optionTypeId") === bedSizeOptionTypeId;
    })
    .get("displayName");

  const constructionOptionValueId = selections
    .find(s => {
      return s.get("optionTypeId") === constructionOptionTypeId;
    })
    .get("id");

  const lineDrawing = lineDrawings.find(l => {
    return l.get("constructionIds").indexOf(constructionOptionValueId) > -1;
  });

  let lineDrawingUrl = null;
  let lineDrawingDescription = null;

  if (lineDrawing) {
    lineDrawingUrl = lineDrawing.get("url");
    lineDrawingDescription = lineDrawing.get("description");
  }

  return {
    productName,
    selectedVariant,
    bedSize,
    lineDrawingUrl,
    lineDrawingDescription,
    lineDrawing
  };
}

export default connect(mapStateToProps, mapDispatchToProps([]))(SleepingInfo);
