export const name = "modalActions";

export const OPEN_MODAL = `${name}_OPEN_MODAL`;
export const CLOSE_MODAL = `${name}_CLOSE_MODAL`;

export function openModal(type, metaData = {}) {
  return {
    type: OPEN_MODAL,
    modalType: type,
    metaData
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}
