import request from "axios";
import { List } from "immutable";

import * as Product from "../model/product";
import { csrfToken, projectParams } from "../lib/helpers";

export const name = "configuratorActions";
export const PRODUCT_FETCHED = `${name}_PRODUCT_FETCHED`;
export const OPTION_SELECTED = `${name}_OPTION_SELECTED`;
export const INCREASE_QUANTITY = `${name}_INCREASE_QUANTITY`;
export const DECREASE_QUANTITY = `${name}_DECREASE_QUANTITY`;
export const CUSTOM_REQUEST_CHANGED = `${name}_CUSTOM_REQUEST_CHANGED`;
export const SIDE_A_DIMENSION_CHANGED = `${name}_SIDE_A_DIMENSION_CHANGED`;
export const SIDE_B_DIMENSION_CHANGED = `${name}_SIDE_B_DIMENSION_CHANGED`;
export const SIDE_C_DIMENSION_CHANGED = `${name}_SIDE_C_DIMENSION_CHANGED`;
export const SECTIONAL_LAYOUT_IS_CUSTOM_CHANGED = `${name}_SECTIONAL_LAYOUT_IS_CUSTOM_CHANGED`;
export const START_CONFIGURATOR = `${name}_START_CONFIGURATOR`;
export const ADD_ITEM_TO_PROJECT_SUCCESS = `${name}_ADD_ITEM_TO_PROJECT_SUCCESS`;
export const UPDATE_NEW_PROJECT_NAME = `${name}_UPDATE_NEW_PROJECT_NAME`;
export const PROJECTS_FETCHED = `${name}_PROJECTS_FETCHED`;
export const SET_PROJECT_NAME = `${name}_SET_PROJECT_NAME`;
export const SET_IS_EDITING = `${name}_SET_IS_EDITING`;
export const CUSTOM_VALUE_CHANGED = `${name}_CUSTOM_VALUE_CHANGED`;
export const CREATING_PROJECT = `${name}_CREATING_PROJECT`;
export const CREATE_PROJECT_SUCCESS = `${name}_CREATE_PROJECT_SUCCESS`;
export const ADDING_ITEM_TO_EXISTING_PROJECT = `${name}_ADDING_ITEM_TO_EXISTING_PROJECT`;
export const CREATE_PROJECT_ERROR = `${name}_CREATE_PROJECT_ERROR`;
export const PROJECT_FETCHED = `${name}_PROJECT_FETCHED`;
export const ADD_ITEM_TO_PROJECT_ERROR = `${name}_ADD_ITEM_TO_PROJECT_ERROR`;
export const UPDATED_VARIATION_IMAGE_URL = `${name}_UPDATED_VARIATION_IMAGE_URL`;
export const EDIT_COM_DETAILS_FIELD = `${name}_EDIT_COM_DETAILS_FIELD`;
export const SAVE_STATE = `${name}_SAVE_STATE`;
export const LOAD_PREVIOUS_STATE = `${name}_LOAD_PREVIOUS_STATE`;

export function productFetched(
  product,
  selections = [],
  customRequest = null,
  optionValuesWithUpcharges = List(),
  quantity = 1,
  comRequiredYardage = 0,
  comDetail = {}
) {
  return {
    type: PRODUCT_FETCHED,
    product,
    selections,
    customRequest,
    optionValuesWithUpcharges,
    quantity,
    comRequiredYardage,
    comDetail
  };
}

export function optionSelected(optionId, optionValueId) {
  return {
    type: OPTION_SELECTED,
    optionId,
    optionValueId
  };
}

export function startConfigurator() {
  return {
    type: START_CONFIGURATOR
  };
}

export function increaseQuantity() {
  return {
    type: INCREASE_QUANTITY
  };
}

export function decreaseQuantity() {
  return {
    type: DECREASE_QUANTITY
  };
}

export function userProjectsFetched(projects) {
  return {
    type: PROJECTS_FETCHED,
    projects
  };
}

export function fetchProduct(productId, lineItemId = null) {
  return function(dispatch, state) {
    return Product.findById(productId).then(product => {
      // Check if we are editing a Product configuration within a Project
      const lineItems = state().projectsStore.getIn(["project", "lineItems"]);
      const projectLineItem = lineItems.find(l => l.get("id") === lineItemId);

      if (projectLineItem) {
        const selections = projectLineItem.get("optionValues");
        const customRequest = projectLineItem.get("customRequest");

        const optionValuesWithUpcharges = projectLineItem.get(
          "optionValuesWithUpcharges"
        );

        const quantity = projectLineItem.get("quantity");
        const comRequiredYardage = projectLineItem.get("comRequiredYardage");
        const comDetail = projectLineItem.get("comDetail").toJS();

        dispatch(
          productFetched(
            product,
            selections,
            customRequest,
            optionValuesWithUpcharges,
            quantity,
            comRequiredYardage,
            comDetail,
          )
        );
      } else {
        dispatch(productFetched(product));
      }
    });
  };
}

export function customRequestChanged(request) {
  return {
    type: CUSTOM_REQUEST_CHANGED,
    request
  };
}

export function sideADimensionChanged(dimension) {
  return {
    type: SIDE_A_DIMENSION_CHANGED,
    dimension
  };
}

export function sideBDimensionChanged(dimension) {
  return {
    type: SIDE_B_DIMENSION_CHANGED,
    dimension
  };
}

export function sideCDimensionChanged(dimension) {
  return {
    type: SIDE_C_DIMENSION_CHANGED,
    dimension
  };
}

export function sectionalLayoutIsCustomChanged(isCustom) {
  return {
    type: SECTIONAL_LAYOUT_IS_CUSTOM_CHANGED,
    isCustom,
  };
}

export function customValueChanged(optionTypeId, optionValueId, value) {
  return {
    type: CUSTOM_VALUE_CHANGED,
    optionTypeId,
    optionValueId,
    value
  };
}

export function editComDetailField(field, value) {
  return {
    type: EDIT_COM_DETAILS_FIELD,
    field,
    value
  };
}

// START_HERE: add dimensions
export function createProject() {
  return function(dispatch, state) {
    const params = projectParams(state);

    dispatch(creatingProject(params));

    return request
      .post("/api/v1/projects/create-project.json", params, {
        headers: { "X-CSRF-Token": csrfToken() }
      })
      .then(response => {
        dispatch(createProjectSuccess());
        return response.data;
      })
      .catch(e => {
        dispatch(createProjectError());
        return e.response.data;
      });
  };
}

export function creatingProject(params) {
  return {
    type: CREATING_PROJECT,
    params
  };
}

export function createProjectSuccess() {
  return {
    type: CREATE_PROJECT_SUCCESS
  };
}

export function createProjectError() {
  return {
    type: CREATE_PROJECT_ERROR
  };
}

export function setProjectName(projectName) {
  return {
    type: SET_PROJECT_NAME,
    projectName
  };
}

export function addItemToExistingProject(id, errorCallback) {
  return function(dispatch, state) {
    const params = projectParams(state);

    dispatch(addingItemToExistingProject(params));

    return request
      .put(`/projects/${id}/add_item.json`, params, {
        headers: { "X-CSRF-Token": csrfToken() }
      })
      .then(response => {
        dispatch(addItemToProjectSuccess());
        return response.data;
      })
      .catch(e => {
        dispatch(addItemToProjectError());
        errorCallback(e.response.data.errors);
      });
  };
}

export function addingItemToExistingProject(params) {
  return {
    type: ADDING_ITEM_TO_EXISTING_PROJECT,
    params
  };
}

export function updateNewProjectName(value) {
  return {
    type: UPDATE_NEW_PROJECT_NAME,
    value
  };
}

export function addItemToProjectSuccess() {
  return {
    type: ADD_ITEM_TO_PROJECT_SUCCESS
  };
}

export function addItemToProjectError() {
  return {
    type: ADD_ITEM_TO_PROJECT_ERROR
  };
}

export function setIsEditing() {
  return {
    type: SET_IS_EDITING
  };
}

export function projectFetched(project, lineItemId) {
  const selectedVariant = project
    .get("lineItems")
    .find(l => {
      return lineItemId === l.get("id");
    })
    .get("selectedVariant");

  return {
    type: PROJECT_FETCHED,
    selectedVariant
  };
}

export function updatedVariationImageURL(url) {
  return {
    type: UPDATED_VARIATION_IMAGE_URL,
    url
  };
}

export function saveState() {
  return {
    type: SAVE_STATE
  };
}

export function loadPreviousState(productId) {
  return {
    type: LOAD_PREVIOUS_STATE,
    productId
  };
}
