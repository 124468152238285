import config from "../config";
import { convertKeysToCamelCase } from "../lib/helpers";

export function getUrl(path) {
  return `${config.apiBase}${path}`;
}

export function transformResponse(data) {
  return convertKeysToCamelCase(data);
}
