import createLogger from "redux-logger";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

import stateToJS from "lib/state-to-js";
import reducers from "reducers/index";
import { setUser, setUserRole, setTradeAccessPending } from "actions/user-actions";

const BROWSER_DEVELOPMENT =
  process.env.NODE_ENV !== "production" && process.env.IS_BROWSER;

// Based on este's configureStore:
// https://github.com/este/este/blob/a1941e4ecce2a2fe86af5226981f2322e0dcfbfd/src/common/configureStore.js
export default function configureStore(initialState) {
  let middleware = [thunk];

  if (BROWSER_DEVELOPMENT) {
    const logger = createLogger({
      collapsed: true,
      stateTransformer: stateToJS
    });
    // Logger must be the last middleware in chain.
    middleware = [...middleware, logger];
  }

  const createReduxStore =
    BROWSER_DEVELOPMENT && window.devToolsExtension
      ? compose(applyMiddleware(...middleware), window.devToolsExtension())
      : applyMiddleware(...middleware);

  return createReduxStore(createStore)(
    reducers,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  );
}

// Store the store on window so that it can be shared between packs
// (which are separate entrypoints)
if (!window.store) {
  window.store = configureStore({});

  if (gon.userId) {
    window.store.dispatch(setUser(gon.userId));
    window.store.dispatch(setUserRole(gon.userRole));
    window.store.dispatch(setTradeAccessPending(gon.tradeAccessPending));
  }
}
const store = window.store;

export { store };
