import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ImmutablePropTypes from 'react-immutable-proptypes';

import Button from "components/shared/button";
import Component from "components/component";
import mapDispatchToProps from "lib/map-dispatch-to-props";
import * as modalActions from "actions/modals";

export class ProductPricingDetailsModal extends Component {
  static propTypes = {
    product: ImmutablePropTypes.map.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    userRole: PropTypes.string
  };

  tableRow = (variant, isLoggedIn, userRole) => {
    const {
      comYardage,
      colYardage,
      tapeTrimYardage,
      displayListPrice,
      displayPrice,
      id,
      printDimensions,
      optionValues,
    } = variant.toObject();

    const hasComYardage = comYardage !== "" && comYardage !== 0;
    const hasColYardage = colYardage !== "" && colYardage !== 0;
    const hasTapeTrimYardage = tapeTrimYardage !== "" && tapeTrimYardage !== 0;
    const bedSize = getBedSize(optionValues);

    return (
      <tr key={id}>
        <td className="sizes-section">
          <div className="size-row mobile-only">
            <p>
              {bedSize && <span>{bedSize}</span>}
              {bedSize && <br />}
              <span dangerouslySetInnerHTML={{ __html: printDimensions }} />
              <br />
              {hasComYardage && <span>COM Yardage: {comYardage}</span>}
              {hasComYardage && <br />}
              {hasColYardage && <span>COL sq. ft.: {colYardage}</span>}
              {hasColYardage && <br />}
              {hasTapeTrimYardage && (
                <span>Tape Trim Yardage: {tapeTrimYardage}</span>
              )}
            </p>
          </div>

          <div className="size-row desktop-only">
            <p>
              {bedSize && <span>{bedSize}</span>}
              {bedSize && <br />}
              <span dangerouslySetInnerHTML={{ __html: printDimensions }} />
              <br />
              {hasComYardage && <span>COM Yardage: {comYardage}</span>}
              {hasComYardage && hasColYardage && <br />}
              {hasColYardage && <span>COL sq. ft.: {colYardage}</span>}
              {((hasComYardage && hasTapeTrimYardage) ||
                (hasColYardage && hasTapeTrimYardage)) && <br />}
              {hasTapeTrimYardage && (
                <span>Tape Trim Yardage: {tapeTrimYardage}</span>
              )}
            </p>
          </div>
        </td>

        <td className="price-section list-price-section">
          <p>{displayListPrice}</p>
        </td>

        {(isLoggedIn && userRole !== 'retail_user') && (
          <td className="price-section trade-price-section">
            <p>{displayPrice}</p>
          </td>
        )}
      </tr>
    );
  }

  openLogin = e => {
    e.preventDefault();
    this.props.actions.modalActions.openModal("login", { prevModal: "product-pricing-details" });
  };

  render() {
    const { product, isLoggedIn, userRole } = this.props;

    if (product.isEmpty()) {
      return null;
    }

    const { defaultLeadTime } = gon;
    const { details, variants, shownIn } = product.toObject();

    // Only show variants that are not associated with any sectional_layouts
    const salientVariants = variants.filter(variant => {
      return variant.get('optionValues').every(optionValue => {
        return optionValue.get('optionTypeName') !== 'sectional_layout';
      });
    });

    console.log({ salientVariants });

    const detailsContent = (
      <div className="details-section">
        <div dangerouslySetInnerHTML={{ __html: details }} />
      </div>
    );

    let shownInContent = null;

    if (shownIn) {
      shownInContent = (
        <div className="shown-in-section">
          <div dangerouslySetInnerHTML={{ __html: shownIn }} />
        </div>
      );
    }

    const hasVariants = salientVariants.size > 0;

    return (
      <div className="pricing-details-wrapper">
        <h3>Pricing & Details</h3>

        {!isLoggedIn && (
          <Fragment>

            <div className="pricing-details-buttons">
              <Button
                onClick={this.openLogin}
              >
                View Trade Pricing
              </Button>
            </div>
          </Fragment>
        )}

        <section>
          <table>
            <thead>
              <tr>
                <th className="size-header">
                  <h4>Size</h4>
                </th>

                <th
                  className={`list-price-header ${
                    !isLoggedIn ? "is-guest" : ""
                  }`}
                >
                  <h4>
                    List<span className="desktop-only"> Price</span>
                  </h4>
                </th>

                {(isLoggedIn && userRole !== 'retail_user') && (
                  <th className="trade-price-header">
                    <h4>
                      Trade<span className="desktop-only"> Price</span>
                    </h4>
                  </th>
                )}
              </tr>
            </thead>

            <tbody>
              {!hasVariants && this.tableRow(product.get("master"), isLoggedIn, userRole)}

              {hasVariants &&
                salientVariants.valueSeq().map((variant, key) => {
                  if (!variant.toObject().discontinued) {
                    // if Variant is not discontinued, render a row.
                    return this.tableRow(variant, isLoggedIn, userRole);
                  }
                })}
            </tbody>
          </table>
        </section>

        <section className="mobile-only">
          <table>
            <tbody>
              <tr>
                <td>
                  <h4>Details</h4>
                  {detailsContent}
                </td>
              </tr>

              {shownInContent && (
                <tr>
                  <td>
                    <h4>Shown In</h4>
                    {shownInContent}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>

        <section className="desktop-only" style={{ marginBottom: '1.875em' }}>
          <table>
            <thead>
              <tr>
                <th className="details-header">
                  <h4 className={shownInContent ? "" : "go-full"}>Details</h4>
                </th>

                {shownInContent && (
                  <th className="shown-in-header">
                    <h4>Shown In</h4>
                  </th>
                )}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{detailsContent}</td>
                {shownInContent && <td>{shownInContent}</td>}
              </tr>
            </tbody>
          </table>
        </section>

        {defaultLeadTime && (
          <section className="desktop-only">
            <table>
              <thead>
                <tr>
                  <th className="details-header">
                    <h4>Lead Time</h4>
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td><p>{defaultLeadTime}</p></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </section>
        )}
      </div>
    );
  }
}

function getBedSize(optionValues) {
  const ov = optionValues.find(ov => ov.get("optionTypeName") === "bed_size");

  if (!ov) {
    return null;
  }

  return ov.get("presentation");
}

function mapStateToProps(state) {
  const isLoggedIn = !!state.userStore.get("userId");  
  const userRole = state.userStore.get("userRole");
  const product = state.configuratorStore.get("product");

  return {
    product,
    isLoggedIn,
    userRole
  };
}

export default connect(
  mapStateToProps, 
  mapDispatchToProps(modalActions)
)(ProductPricingDetailsModal);
