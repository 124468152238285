import axios from "axios";
import { deserialize } from "lib/deserializers";

import { getUrl } from "./api";

export const findCurrentUser = () => {
  const url = getUrl(`/profile`);
  return axios.get(url).then(response => {
    return deserialize(response.data);
  });
};

export default findCurrentUser;
