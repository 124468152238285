import { fromJS, List } from "immutable";

import {
  FETCHING_PROJECT,
  FETCH_PROJECT_SUCCESS,
  SET_PROJECT,
  UPDATING_PROJECT_ITEM,
  UPDATE_PROJECT_ITEM_SUCCESS,
  UPDATE_PROJECT_ITEM_ERROR
} from "../actions/projects";

const initialState = fromJS({
  fetching: false,
  project: {
    id: "",
    slug: "",
    name: "",
    number: "",
    lineItems: List()
  },
  updating: false
});

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PROJECT:
      return state.set("fetching", true);

    case FETCH_PROJECT_SUCCESS:
      return state.set("fetching", false);

    case SET_PROJECT:
      return state.set("project", action.data);

    case UPDATING_PROJECT_ITEM:
      return state.set("updating", true);

    case UPDATE_PROJECT_ITEM_SUCCESS:
    case UPDATE_PROJECT_ITEM_ERROR:
      return state.set("updating", false);
  }

  return state;
};
