import React from "react";

import Component from "components/component";

export default class TradeAccessPending extends Component {
  render() {
    return (
      <div className="status-info info-modal-body">
        <h2 class="headline">
          Your trade access is currently pending.
        </h2>
        <div class="modal-content">
          <p>
            You'll receive a confirmation email once your trade membership request has been reviewed.
          </p>
        </div>
        <a href="/collection">Shop Our Collections</a>
      </div>
    );
  }
}
