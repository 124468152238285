import { fromJS, Map } from "immutable";

import { isSelectable, numberFormat, stripZeros } from "lib/helpers";
import { getProductImageName } from "selectors/configurator-selectors";
import {
  PRODUCT_FETCHED,
  OPTION_SELECTED,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  CUSTOM_REQUEST_CHANGED,
  SIDE_A_DIMENSION_CHANGED,
  SIDE_B_DIMENSION_CHANGED,
  SIDE_C_DIMENSION_CHANGED,
  SECTIONAL_LAYOUT_IS_CUSTOM_CHANGED,
  START_CONFIGURATOR,
  ADD_ITEM_TO_PROJECT_SUCCESS,
  UPDATE_NEW_PROJECT_NAME,
  SET_PROJECT_NAME,
  SET_IS_EDITING,
  CUSTOM_VALUE_CHANGED,
  CREATING_PROJECT,
  CREATE_PROJECT_SUCCESS,
  ADDING_ITEM_TO_EXISTING_PROJECT,
  CREATE_PROJECT_ERROR,
  PROJECT_FETCHED,
  ADD_ITEM_TO_PROJECT_ERROR,
  UPDATED_VARIATION_IMAGE_URL,
  EDIT_COM_DETAILS_FIELD,
  SAVE_STATE,
  LOAD_PREVIOUS_STATE
} from "actions/configurator-actions";

const initialState = fromJS({
  product: {},
  optionTypes: {},
  selectedVariant: {},
  comYardageVariant: {},
  selections: new Map(),
  quantityCount: 1,
  customRequest: "",
  userStarted: false,
  projectName: "",
  creatingProject: false,
  addingItemToExistingProject: false,
  isEditing: false,
  variationImageURL: "",
  persistVariationImageURL: false,
  isStaffUser: false,
  comRequiredYardage: 0,
  comDetail: {},
  comDetailUpdate: {},
});

const shouldUpdateComYardage = ({ originalComRequiredYardage, selectedVariant, selection }) => {
  // Yardage shouldn't consider updating unless the width or size settigs change.
  const whiteListedProperties = ['width', 'size'];
  let shouldCompare = false;
  whiteListedProperties.map(prop => {
    if (selection.get('name').includes(prop)) shouldCompare = true;
  });
  if (shouldCompare) return originalComRequiredYardage !== selectedVariant.get("comRequiredYardage");
  return false;
};

export default (state = initialState, action) => {
  let selection;
  let selectedVariant;

  switch (action.type) {
    case SAVE_STATE:
      localStorage.setItem('previousState', JSON.stringify(state.toJS()));
      return state;

    case LOAD_PREVIOUS_STATE:
      const previousState = localStorage.getItem('previousState');
      if (previousState) {
        const prevState = JSON.parse(previousState);
        if (parseInt(prevState.product.id) === parseInt(action.productId)) {
          console.log(state);
          state = fromJS(prevState);
          console.log(state);
        }
        localStorage.removeItem('previousState');
      }
      return state;

    case PRODUCT_FETCHED:
      const product = fromJS(action.product);
      const optionTypes = formatOptionTypes(
        product.get("configurationOptions")
      );

      if (product.get("isStaffUser")) {
        state = state.set("isStaffUser", true);
      }

      state = state
        .set("product", product)
        .set("optionTypes", optionTypes)
        .set("customRequest", action.customRequest || "")
        .set("quantityCount", action.quantity)
        .set("comRequiredYardage", action.comRequiredYardage)
        .set("comDetail", action.comDetail)
        .set("comDetailUpdate", action.comDetail);

      let selections = selectDefaultOptions(product, optionTypes);

      selections = action.selections.reduce((sels, selection) => {
        const { optionTypeId, id } = selection.toObject();
        const optionValue = optionTypes.getIn([
          optionTypeId,
          "optionValues",
          id
        ]);

        if (optionValue) {
          return sels.set(optionTypeId, optionValue);
        } else {
          return sels;
        }
      }, selections);

      state = state.set("selections", selections);

      state = selections.reduce((prevState, selection) => {
        return ensureNoConflictingSelections(prevState, selection.get("id"));
      }, state);

      // Assign any existing custom options to already-selected selections
      const optionValuesWithUpcharges = action.optionValuesWithUpcharges;

      if (optionValuesWithUpcharges.size > 0) {
        state = assignExistingUpcharges(state, optionValuesWithUpcharges);
      }

      selectedVariant = state.get("selectedVariant");

      if (selectedVariant.isEmpty()) {
        // If the product has variants, select first one
        // If no variants, select master
        if (product.get("hasVariants")) {
          selectedVariant = product.get("variants").first();
          state = state.set("selectedVariant", selectedVariant);
        } else {
          selectedVariant = product.get("master");
          state = state.set("selectedVariant", selectedVariant);
        }

        // Set correct upcharges to selections if starting a new Product (non-editing)
        state = state.set(
          "selections",
          state.get("selections").map(selection => {
            return selection.set(
              "upchargeAmount",
              determineUpchargeAmount(selection, selectedVariant)
            );
          })
        );
      }

      state = setVariationImageURL(state);

      // If not editing a line item set the initial COM Required Yardage
      // When editing a line item the COM Required Yardaeg is on the Line ITem
      if (!state.get("isEditing")) {
        state = setCOMRequiredYardage(state);
      }

      return state;

    case PROJECT_FETCHED:
      return state.set("selectedVariant", action.selectedVariant);

    case OPTION_SELECTED:
      // TODO: Possibly clear customValues and customPrices from previously selected option
      // At the moment it does not affect pricing or persistance so not a critical fix
      selection = state.getIn([
        "optionTypes",
        action.optionId,
        "optionValues",
        action.optionValueId
      ]);

      selectedVariant = state.get("selectedVariant");
      const originalComRequiredYardage = state.get("comRequiredYardage");
      const defaultVariant = state.getIn(["product", "master"]);
      const selectionHasVariant = !!selection.get("variant");

      // If the selection has an associated Variant,
      // set the selectedVariant to that one.
      //
      // If there is not an associated Variant,
      // selected the existing selectedVariant or the Product's default
      if (selectionHasVariant) {
        selectedVariant = selection.get("variant");
      } else {
        selectedVariant = selectedVariant || defaultVariant;
      }

      const hasExistingSelectionsWithUpchargeAmount = state
        .get("selections")
        .some(s => {
          return !!s.get("upchargeConfiguration");
        });

      if (selection.get("upchargeConfiguration")) {
        selection = selection.set(
          "upchargeAmount",
          determineUpchargeAmount(selection, selectedVariant)
        );
      } else if (hasExistingSelectionsWithUpchargeAmount) {
        state = updateExistingSelectionsWithUpchargeAmount(
          state,
          selection,
          selectedVariant
        );
      }

      state = state
        .set("selectedVariant", selectedVariant)
        .setIn(["selections", action.optionId], selection);

      state = ensureNoConflictingSelections(state, action.optionValueId);
      state = ensureSelectionsWhereNecessary(state, action.optionValueId);
      state = setVariationImageURL(state);

      if (selection.get("isCustom") && !!selection.get("customValue")) {
        state = updateStateOnCustomValueChange(
          state,
          selection.get("customValue"),
          action.optionId,
          action.optionValueId
        );
      } else if (shouldUpdateComYardage({
        originalComRequiredYardage,
        selectedVariant,
        selection
      })) {
        state = setCOMRequiredYardage(state);
      }

      return state;

    case INCREASE_QUANTITY:
      return state.set("quantityCount", state.get("quantityCount") + 1);

    case DECREASE_QUANTITY:
      if (!(state.get("quantityCount") === 0)) {
        return state.set("quantityCount", state.get("quantityCount") - 1);
      }

    case CUSTOM_REQUEST_CHANGED:
      return state.set("customRequest", action.request);

    case SIDE_A_DIMENSION_CHANGED:
      return state.set("sideADimension", action.dimension);

    case SIDE_B_DIMENSION_CHANGED:
      return state.set("sideBDimension", action.dimension);

    case SIDE_C_DIMENSION_CHANGED:
      return state.set("sideCDimension", action.dimension);

    case SECTIONAL_LAYOUT_IS_CUSTOM_CHANGED:
      return state.set("sectionalLayoutIsCustom", action.isCustom);

    case START_CONFIGURATOR:
      return state.set("userStarted", true);

    case UPDATE_NEW_PROJECT_NAME:
      return state.set("projectName", action.value);

    case ADDING_ITEM_TO_EXISTING_PROJECT:
      return state.set("addingItemToExistingProject", true);

    case CREATE_PROJECT_SUCCESS:
    case CREATE_PROJECT_ERROR:
      return state.set("creatingProject", false);

    case ADD_ITEM_TO_PROJECT_SUCCESS:
    case ADD_ITEM_TO_PROJECT_ERROR:
      return state.set("addingItemToExistingProject", false);

    case SET_PROJECT_NAME:
      return state.set("projectName", action.projectName);

    case SET_IS_EDITING:
      return state.set("isEditing", true);

    case CUSTOM_VALUE_CHANGED:
      state = updateStateOnCustomValueChange(
        state,
        action.value,
        action.optionTypeId,
        action.optionValueId
      );
      return state;

    case CREATING_PROJECT:
      return state.set("creatingProject", true);

    case UPDATED_VARIATION_IMAGE_URL:
      return state
        .set("variationImageURL", action.url)
        .set("persistVariationImageURL", false);
    case EDIT_COM_DETAILS_FIELD:
      const comDetail = { ...state.get("comDetailUpdate") };
      if (['width', 'yards', 'square_feet'].includes(action.field)) {
        comDetail[action.field] = action.value * 1.0;
      } else {
        comDetail[action.field] = action.value;
      }
      return state.set("comDetailUpdate", comDetail);
  }

  return state;
};

function setCustomValueInState(state, optionTypeId, optionValueId) {
  const optionValue = getCustomOptionValue(state, optionTypeId, optionValueId);

  let upchargeAmount = null;
  let displayCustomValuePrice = null;
  let selectedVariant = Map();
  let comRequiredYardage = null;

  if (optionValue) {
    if (isArray(optionValue)) {
      const priceDifferenceBetweenVariants = Math.abs(
        optionValue[1].get("upchargeAmount") -
          optionValue[0].get("upchargeAmount")
      );

      // Use the contant from window, which is loaded into the application.html.erb
      // template rendered from the server. This allows the server and the front-end
      // to use the same value, set in one place (initializers/constants.rb).
      upchargeAmount = priceDifferenceBetweenVariants / 2 + window.CUSTOM_SIZE_FEE;
      selectedVariant = optionValue[0].get("variant");
      comRequiredYardage = optionValue[1].getIn(["variant", "comYardage"]);
    } else {
      upchargeAmount = window.CUSTOM_SIZE_FEE;
      selectedVariant = optionValue.get("variant");
      comRequiredYardage = selectedVariant.get("comYardage");
    }

    const customPrice = (selectedVariant.get("price") + upchargeAmount) / 100;
    displayCustomValuePrice = `$${stripZeros(
      numberFormat().format(customPrice)
    )}`;
  }

  if (displayCustomValuePrice) {
    state = state.setIn(
      [
        "optionTypes",
        optionTypeId,
        "optionValues",
        optionValueId,
        "displayCustomValuePrice"
      ],
      displayCustomValuePrice
    );
  }

  if (upchargeAmount) {
    state = state.setIn(
      [
        "optionTypes",
        optionTypeId,
        "optionValues",
        optionValueId,
        "upchargeAmount"
      ],
      upchargeAmount
    );
  }

  state = state.set("comRequiredYardage", comRequiredYardage);

  return state.set("selectedVariant", selectedVariant);
}

function getCustomOptionValue(store, optionTypeId, optionValueId) {
  const optionValue = store.getIn([
    "optionTypes",
    optionTypeId,
    "optionValues",
    optionValueId
  ]);
  const customValue = parseFloat(optionValue.get("customValue"), 10);

  // If customValue is not present or an empty string, price should be 0
  // otherwise it will retain the last existing customPrice
  if (!customValue) {
    return null;
  }

  const optionValues = store
    .getIn(["optionTypes", optionTypeId, "optionValues"])
    .filter(o => !o.get("isCustom"));

  return getClosestOptionValueByValue(optionValues, customValue);
}

function getClosestOptionValueByValue(optionValues, customValue) {
  // Given an arbitrary value, this function attempts to find the option value
  // that has the smallest difference between the given value and the option value's value

  return optionValues.reduce((previousOptionValue, currentOptionValue) => {
    // If the previous option value is an array it is because we found
    // an identical size difference between two options.
    // If identical we return both values in order to complete the identical formula:
    // (price difference between two options / 2) + CUSTOM_SIZE_FEE
    if (isArray(previousOptionValue)) {
      return previousOptionValue;
    }

    const currentValue = parseFloat(currentOptionValue.get("displayName"), 10);
    const currentDifference = Math.abs(currentValue - customValue);
    const previousValue = parseFloat(
      previousOptionValue.get("displayName"),
      10
    );
    const previousDifference = Math.abs(previousValue - customValue);

    if (previousDifference === currentDifference) {
      return [previousOptionValue, currentOptionValue];
    } else if (previousDifference > currentDifference) {
      return currentOptionValue;
    } else {
      return previousOptionValue;
    }
  });
}

function selectDefaultOption(optionType, defaultConfiguration) {
  const { id, optionValues } = optionType.toObject();
  // Don't auto-select any option values that are custom
  const nonCustomOptionValues = optionValues.filter(ov => !ov.get("isCustom"));

  // By default, select the first non-custom option value in the Option Type
  let option = nonCustomOptionValues.first();

  // But if the product has a default_configuration that matches this Option Type,
  // use that one instead
  if (defaultConfiguration && defaultConfiguration.get(id)) {
    option = optionValues.find(
      o => o.get("id") === defaultConfiguration.get(id)
    );
  }

  return option;
}

function selectDefaultOptions(product, optionTypes) {
  const defaultConfiguration = product.get("defaultConfiguration");

  return optionTypes.reduce((sels, optionType) => {
    const id = optionType.get("id");
    const option = selectDefaultOption(optionType, defaultConfiguration);
    return sels.set(id, option);
  }, Map());
}

function ensureNoConflictingSelections(state, optionValueId) {
  // Does this Product have any option value restrictions?
  const optionValueRestrictions = state.getIn([
    "product",
    "optionValueRestrictions"
  ]);

  if (optionValueRestrictions.size === 0) {
    return state;
  }

  // Are there any restrictions pertaining to the current selected option value?
  const restrictionsForOptionValue = optionValueRestrictions.get(optionValueId);

  if (!restrictionsForOptionValue) {
    return state;
  }

  let selections = state.get("selections");

  // Do any existing selections conflict with the current selection?
  const conflictingSelections = selections.filter(s => {
    return restrictionsForOptionValue.indexOf(s.get("id")) > -1;
  });

  if (!conflictingSelections.size === 0) {
    return state;
  }

  // If yes, deselect conflicting selections
  const optionTypes = state.get("optionTypes");
  const conflictingSelectionIds = conflictingSelections.valueSeq().map(s => {
    return s.get("id");
  });

  selections = selections.reduce((sels, curr, optionTypeId) => {
    const selectionId = curr.get("id");

    if (selectionId === optionValueId) {
      return sels;
    }

    // If the conflicting selections include this selection
    if (conflictingSelectionIds.indexOf(selectionId) > -1) {
      const conflictingSiblingIds = restrictionsForOptionValue.filter(rId => {
        return rId !== selectionId;
      });

      // Find another option value that doesn't conflict with it
      const newOptionValue = optionTypes
        .getIn([optionTypeId, "optionValues"])
        .find(ov => {
          const newOptionValueId = ov.get("id");
          // If the optionValue is also not a restriction
          // If the optionValue is not the current option value
          // If the optionValue is not custom
          return (
            conflictingSiblingIds.indexOf(newOptionValueId) === -1 &&
            newOptionValueId !== selectionId &&
            !ov.get("isCustom")
          );
        });

      if (newOptionValue) {
        return sels.set(optionTypeId, newOptionValue);
      } else {
        return sels.remove(optionTypeId);
      }
    }

    return sels;
  }, selections);

  return state.set("selections", selections);
}

// When de-selecting Option Values that prohibits an Option Type,
// ensure that the prohibited Option Types has a default selection.
// Ex. the 'Top Material - Marble' option value prohibits all Mirror options
// If user re-selects 'Top Material - Mirror' option value,
// ensure that a default mirror option is re-selected as it would have been removed previously.
function ensureSelectionsWhereNecessary(state) {
  let { optionTypes, selections } = state.toObject();
  const optionValueRestrictions = state.getIn([
    "product",
    "optionValueRestrictions"
  ]);
  const defaultConfiguration = state.get("defaultConfiguration");

  selections = optionTypes.reduce((sels, optionType) => {
    const optionTypeId = optionType.get("id");

    if (sels.has(optionTypeId)) {
      return sels;
    } else {
      const defaultOption = selectDefaultOption(
        optionType,
        defaultConfiguration
      );

      if (
        defaultOption &&
        isSelectable(
          selections,
          optionValueRestrictions,
          defaultOption.get("id")
        )
      ) {
        return sels.set(optionTypeId, defaultOption);
      } else {
        return sels;
      }
    }
  }, selections);

  return state.set("selections", selections);
}

function formatOptionTypes(optionTypes) {
  return optionTypes.sortBy(ot => ot.get("position")).map(optionType => {
    const sortedOptionValues = optionType
      .get("optionValues")
      .sortBy(ov => ov.get("position"));
    return optionType.set("optionValues", sortedOptionValues);
  });
}

function assignExistingUpcharges(state, optionValuesWithUpcharges) {
  const selections = state.get("selections");

  const updatedState = optionValuesWithUpcharges.reduce(
    (prevState, optionValue) => {
      const {
        optionValueId,
        upchargeAmount,
        customValue
      } = optionValue.toObject();

      const optionValueSelection = selections.find(
        s => s.get("id") === optionValueId
      );

      if (optionValueSelection) {
        let adjustedUpchargeAmount = upchargeAmount;

        const selection = optionValueSelection.toObject();
        const upchargeConfiguration = selection["upchargeConfiguration"];

        if (upchargeConfiguration !== '' && upchargeConfiguration != null) {
          // In some cases, upchargeConfiguration is blank.
          if (upchargeConfiguration.toObject()['amountType'] === "percentage") {
            adjustedUpchargeAmount = determineUpchargeAmount(
              optionValueSelection,
              state.get('selectedVariant')
            );
          }
        }

        const optionTypeId = optionValueSelection.get("optionTypeId");

        prevState = prevState
          .setIn(["selections", optionTypeId, "upchargeAmount"], adjustedUpchargeAmount)
          .setIn([
            "optionTypes",
            optionTypeId,
            "optionValues",
            optionValueId,
            "upchargeAmount"
          ], adjustedUpchargeAmount);

        if (customValue) {
          prevState = prevState
            .setIn(["selections", optionTypeId, "customValue"], customValue)
            .setIn([
              "optionTypes",
              optionTypeId,
              "optionValues",
              optionValueId,
              "customValue"
            ], customValue);
        }
      }

      return prevState;
    },
    state
  );

  return updatedState;
}

function setVariationImageURL(state) {
  let url = "";
  let persist = false;

  if (state.getIn(["product", "hasVariationImages"])) {
    const productType = state.getIn(["product", "productType"]);
    const name = getProductImageName(state);

    if (name) {
      url = `${gon.productImageBasePath}/${productType}/${name}.jpg`;
      persist = true;
    }
  }

  return state
    .set("variationImageURL", url)
    .set("persistVariationImageURL", persist);
}

function isArray(v) {
  return v.constructor === Array;
}

function determineUpchargeAmount(selection, selectedVariant) {
  const upchargeConfiguration = selection.get("upchargeConfiguration");

  if (!upchargeConfiguration) {
    return 0;
  }

  const { amount, amountType, applyAgainst } = upchargeConfiguration.toObject();

  if (amountType === UPCHARGE_TYPE_PERCENTAGE) {
    if (applyAgainst === UPCHARGE_AGAINST_VARIANT) {
      const variantPrice = selectedVariant.get("price");
      return variantPrice * (amount / 100.0);
    }
  } else if (amountType === UPCHARGE_TYPE_FLAT) {
    if (applyAgainst === UPCHARGE_AGAINST_NONE) {
      return amount;
    }

    if (applyAgainst === UPCHARGE_AGAINST_COM) {
      return selectedVariant.get("comYardage") * amount;
    }
  }

  return 0;
}

function updateExistingSelectionsWithUpchargeAmount(
  state,
  newSelection,
  selectedVariant
) {
  let selections = state.get("selections");

  selections = selections.map(selection => {
    if (selection.get("upchargeConfiguration")) {
      selection = selection.set(
        "upchargeAmount",
        determineUpchargeAmount(selection, selectedVariant)
      );
    }

    return selection;
  });

  return state.set("selections", selections);
}

function updateStateOnCustomValueChange(
  state,
  customValue,
  optionTypeId,
  optionValueId
) {
  state = state.setIn(
    ["optionTypes", optionTypeId, "optionValues", optionValueId, "customValue"],
    customValue
  );
  state = setCustomValueInState(state, optionTypeId, optionValueId);

  let selection = state.getIn([
    "optionTypes",
    optionTypeId,
    "optionValues",
    optionValueId
  ]);

  const selectedVariant = state.get("selectedVariant");

  const hasExistingSelectionsWithUpchargeConfiguration = state
    .get("selections")
    .some(s => {
      return !!s.get("upchargeConfiguration");
    });

  if (selection.get("upchargeConfiguration")) {
    selection = selection.set(
      "upchargeAmount",
      determineUpchargeAmount(selection, selectedVariant)
    );
  } else if (hasExistingSelectionsWithUpchargeConfiguration) {
    state = updateExistingSelectionsWithUpchargeAmount(
      state,
      selection,
      selectedVariant
    );
  }

  return state.setIn(["selections", optionTypeId], selection);
}

function setCOMRequiredYardage(state) {
  const variant = state.get("selectedVariant");
  const variantComYardage = variant.get("comYardage");
  return state.set("comRequiredYardage", variantComYardage);
}
