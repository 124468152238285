import { fromJS } from "immutable";

import { address as addressSchema } from "./schemas";
import {
  convertIdsToString,
  convertKeysToCamelCase,
  convertNullsToEmptyString
} from "./helpers";

export function deserialize(data) {
  data = convertIdsToString(data);
  data = convertKeysToCamelCase(data);
  data = convertNullsToEmptyString(data);

  return data;
}

export function deserializeOrder(order) {
  // if there is no ship_address or bill_address on the order
  // Spree will return these as null when in the 'cart' state
  // this instantiates them with a blank address schema
  if (!order.ship_address) order.ship_address = addressSchema();
  if (!order.bill_address) order.bill_address = addressSchema();

  // this is not a default field on the Spree order response
  // we use this to store the user's cc info while they are adding a new cc

  return fromJS(deserialize(order));
}
