import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Component from "components/component";
import mapDispatchToProps from "lib/map-dispatch-to-props";

export class SectionalDrawings extends Component {
  static propTypes = {
  };

  render() {
    return (
      <div>
        <img src={this.props.metaData.thumbnail} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps([]))(SectionalDrawings);
