import React from "react";
import PropTypes from "prop-types";
import Component from "components/component";

export default class ProductZoom extends Component {
  static propTypes = {
    metaData: PropTypes.object
  };

  render() {
    return (
      <div className="image-wrapper">
        <img src={this.props.metaData.imgSrc} />
      </div>
    );
  }
}
