import React from "react";
import PropTypes from "prop-types";
import Component from "components/component";
import request from "axios";
import { csrfToken } from "../../lib/helpers";

export default class ShareProjectAndOrderModal extends Component {
  static propTypes = {
    metaData: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      toEmail: "",
      message: "",
      showPrices: false,
      success: false,
      errors: {},
      sending: false
    };
  }

  handleChangeShowPrices = e => {
    this.setState({ showPrices: e.target.checked });
  };

  handleChange = (field, e) => {
    let state = {};
    state[field] = e.target.value;

    this.setState(state);
  };

  handleShareResource = e => {
    e.preventDefault();

    const id = this.props.metaData.id;
    const clientId = this.props.metaData.clientId;

    this.setState(
      {
        errors: {},
        sending: true
      },
      () => {
        const type = this.props.metaData.type;
        let url = `/${type}s/${id}/share.json`;

        if (clientId) {
          url += `?client_id=${clientId}`;
        }

        const body = {
          from_name: this.state.fromName,
          to_email: this.state.toEmail,
          message: this.state.message,
          show_prices: this.state.showPrices
        };

        return request
          .post(url, body,
            { headers: { "X-CSRF-Token": csrfToken() } }, {
              validateStatus: s => {
                return false;
              }
            })
          .then(response => {
            this.setState({
              success: true,
              sending: false
            });
          })
          .catch(e => {
            const errors = e.response.data.errors || {
              general: "There was an error with your request"
            };

            this.setState({
              errors,
              sending: false
            });
          });
      }
    );
  };

  render() {
    const {
      toEmail,
      message,
      showPrices,
      success,
      errors,
      sending
    } = this.state;

    if (success) {
      return (
        <div>
          <h2 className="headline">Share</h2>

          <div className="share-success-message">
            <p>Message Sent</p>
          </div>
        </div>
      );
    }

    const header = window.location.href.includes("projects") ? (
      <h2 className="headline">Share Quote</h2>
    ) : (
      <h2 className="headline">Share Order</h2>
    );

    const fromValue = `${gon.userFullName}  (${gon.userEmail})`;

    const showPricingClass = this.state.showPrices ? "checked" : "";

    return (
      <div>
        {header}

        <div className="share-form-wrapper" id="share-form-wrapper">
          <form id="share-form">
            <div className="error-notice">{errors.general}</div>

            <div className="grid-row">
              <div className="col-12-12">
                <div className="label-holder">
                  <label>Recipient Email</label>
                </div>

                <input
                  type="email"
                  placeholder="Recipient Email"
                  id="share_to_email"
                  onChange={this.handleChange.bind(this, "toEmail")}
                  value={toEmail}
                  className={
                    errors.to_email ? "error text-input" : "text-input"
                  }
                />

                <div className="error-notice">{errors.to_email}</div>
              </div>
            </div>

            <div className="grid-row">
              <div className="col-12-12">
                <div className="label-holder">
                  <label>From</label>
                </div>

                <input
                  type="hidden"
                  id="share_from_name"
                  value={gon.userFullName}
                  readOnly
                />

                <input
                  type="text"
                  id="display_from_field"
                  value={fromValue}
                  readOnly
                />

                <div className="error-notice">{errors.from_name}</div>
              </div>
            </div>

            <div className="grid-row">
              <div className="col-12-12">
                <div className="label-holder">
                  <label>Message (Optional)</label>
                </div>

                <textarea
                  id="share_message"
                  onChange={this.handleChange.bind(this, "message")}
                  value={message}
                />
              </div>
            </div>

            <div className="grid-row">
              <div className="col-12-12">
                <p className="pricing-label">Pricing (Optional)</p>
                <div className="checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="show_pricing"
                    onChange={this.handleChangeShowPrices}
                    checked={showPrices}
                  />

                  <label htmlFor="show_pricing" className={showPricingClass}>
                    Show Pricing
                  </label>
                </div>
              </div>
            </div>

            <div className="grid-row">
              <div className="col-12-12 push-right">
                <input
                  type="submit"
                  className="large right"
                  disabled={sending}
                  value={sending ? "Sending..." : "Send Quote"}
                  onClick={this.handleShareResource}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
