import React from "react";

import Component from "components/component";

export default class StatusDetails extends Component {
  render() {
    return (
      <div className="status-info info-modal-body">
        <h2 className="headline">Status Details</h2>
        <table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Explanation</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pending</td>
              <td>
                Your order has been received, but requires review by your sales
                rep prior to commencing production.
              </td>
            </tr>
            <tr>
              <td>In Production</td>
              <td>Your order has been submitted and is in progress.</td>
            </tr>
            <tr>
              <td>On Hold</td>
              <td>There is a detail outstanding to proceed with your order.</td>
            </tr>
            <tr>
              <td>Complete</td>
              <td>Your Order Is Complete</td>
            </tr>
            <tr>
              <td>Closed</td>
              <td>Your order has been collected from our workroom.</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
