import React from "react";
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from "react-redux";

import Component from "components/component";

export class ViewGallery extends Component {
  static propTypes = {
    product: ImmutablePropTypes.map.isRequired,
  };

  render() {
    const images = this.props.product.toJSON().secondaryImagePaths;

    return (
      <div>
        {images.map((image, index) => (
          <img style={{ width: '100%', marginBottom: -3 }} key={index} src={image} />
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const product = state.configuratorStore.get("product");

  return {
    product,
  };
}

export default connect(mapStateToProps)(ViewGallery);
