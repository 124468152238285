import React from "react";

const ulStyles = {
  listStyleType: 'disc',
};

const liStyles = {
  paddingLeft: 0,
};

const h2Styles = {
  width: '65%',
};

const ResaleFormInstructions = () => (
  <div className="resale-form-instructions">
    <h2 className="headline" style={h2Styles}>Resale Certificate Instructions</h2>
    <div className="modal-content">
      <ol className="left">
        <li style={liStyles}>Download the appropriate form:</li>
        <ul style={ulStyles}>
          <li style={liStyles}><a href="/california_resale_certificate_in_state.pdf" target="_blank" rel="noopener noreferrer">CA in-state</a></li>
          <li style={liStyles}><a href="/california_resale_certificate_out_of_state.pdf" target="_blank" rel="noopener noreferrer">Out-of-state</a></li>
        </ul>
        <li style={liStyles}>Email the completed form along with a copy of your seller's permit to <a id="salesPersonEmail" /></li>
      </ol>
    </div>
  </div>
);

export default ResaleFormInstructions;
