import { fromJS, Map } from "immutable";

import { OPEN_MODAL, CLOSE_MODAL } from "../actions/modals";

const initialState = fromJS({
  open: false,
  type: "",
  metaData: {}
});

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return state
        .set("open", true)
        .set("type", action.modalType)
        .set("metaData", action.metaData);

    case CLOSE_MODAL:
      return state
        .set("open", false)
        .set("type", "")
        .set("metaData", Map());
  }

  return state;
};
