import request from "axios";
import { fromJS } from "immutable";

export const name = "countryActions";
export const FETCHED_COUNTRIES = `${name}_FETCHED_COUNTRIES`;
export const FETCHED_STATES = `${name}_FETCHED_STATES`;

export function fetchCountries() {
  return function(dispatch, state) {
    return request.get("/api/v1/countries/all.json").then(response => {
      dispatch(
        fetchedCountries(fromJS(formatForReactSelect(response.data.countries)))
      );
    });
  };
}

function fetchedCountries(countries) {
  return {
    type: FETCHED_COUNTRIES,
    countries
  };
}

export function fetchStates(countryId) {
  return function(dispatch, state) {
    return request
      .get(`/api/v1/states.json?country_id=${countryId}`)
      .then(response => {
        dispatch(
          fetchedStates(fromJS(formatForReactSelect(response.data.states)))
        );
        return response.data.states;
      });
  };
}

function fetchedStates(states) {
  return {
    type: FETCHED_STATES,
    states
  };
}

function formatForReactSelect(list) {
  return list.map(item => {
    return {
      label: item.name,
      value: item.id.toString()
    };
  });
}
