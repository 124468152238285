import React from "react";
import PropTypes from "prop-types";
import Component from "components/component";

export default class ProjectUpdateItemError extends Component {
  static propTypes = {
    metaData: PropTypes.object
  };

  render() {
    const { error } = this.props.metaData;

    return (
      <div id="project-update-item-error">
        <p>This item was unabled to be updated: {error}.</p>
      </div>
    );
  }
}
