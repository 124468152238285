import React from "react";
import PropTypes from "prop-types";
import Component from "components/component";
import request from "axios";
import { csrfToken } from "../../lib/helpers";

export default class SendPaymentForm extends Component {
  static propTypes = {
    metaData: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      toEmail: "",
      message: "",
      success: false,
      errors: {},
      sending: false
    };
  }

  handleChange = (field, e) => {
    let state = {};
    state[field] = e.target.value;

    this.setState(state);
  };

  handleSend = e => {
    e.preventDefault();

    const id = this.props.metaData.id;

    this.setState(
      {
        errors: {},
        sending: true
      },
      () => {
        const body = {
          from_name: this.state.fromName,
          to_email: this.state.toEmail,
          message: this.state.message
        };
        return request
          .post(`/orders/${id}/send-payment-form.json`, body,
            { headers: { "X-CSRF-Token": csrfToken() } }, {
              validateStatus: s => {
                return false;
              }
            })
          .then(response => {
            this.setState({
              success: true,
              sending: false
            });
          })
          .catch(e => {
            const errors = e.response.data.errors || {
              general: "There was an error with your request"
            };

            this.setState({
              errors,
              sending: false
            });
          });
      }
    );
  };

  render() {
    const { toEmail, message, success, errors, sending } = this.state;

    if (success) {
      return (
        <div>
          <h2 className="headline">Send Payment Form to Client</h2>

          <div className="send-success-message">
            <p>Your request was sent successfully.</p>
          </div>
        </div>
      );
    }

    const fromValue = `${gon.userFullName}  (${gon.userEmail})`;

    return (
      <div>
        <h2 className="headline">Send Payment Form to Client</h2>

        <p>
          Fill out this form to send your client a link which will allow them to
          pay the remaining balance for this order by credit card.
        </p>

        <div>
          <form>
            <div className="error-notice">{errors.general}</div>

            <div className="grid-row">
              <div className="col-6-12">
                <div className="label-holder">
                  <label>From</label>
                </div>

                <input
                  type="hidden"
                  id="share_from_name"
                  value={gon.userFullName}
                  readOnly
                />

                <input
                  type="text"
                  id="display_from_field"
                  value={fromValue}
                  readOnly
                />

                <div className="error-notice">{errors.from_name}</div>
              </div>

              <div className="col-6-12">
                <div className="label-holder">
                  <label>To*</label>
                </div>

                <input
                  type="email"
                  id="send_to_email"
                  placeholder="Client's Email"
                  onChange={this.handleChange.bind(this, "toEmail")}
                  value={toEmail}
                  className={
                    errors.to_email ? "error text-input" : "text-input"
                  }
                />

                <div className="error-notice">{errors.to_email}</div>
              </div>
            </div>

            <div className="grid-row">
              <div className="col-12-12">
                <div className="label-holder">
                  <label>Message (Optional)</label>
                </div>

                <textarea
                  id="send_message"
                  onChange={this.handleChange.bind(this, "message")}
                  placeholder="Please visit the enclosed link to make a payment on your remaining balance."
                  value={message}
                />
              </div>
            </div>

            <div className="grid-row">
              <div className="col-12-12">
                <input
                  type="submit"
                  className="large right"
                  value={sending ? "Sending..." : "Send"}
                  onClick={this.handleSend}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
